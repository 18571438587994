import axios from "axios";

const authHeader = "";

export const getSingleDomainAnalysis = async (uid, ip) => {
	return await axios.get(
		`${process.env.REACT_APP_BASIC_API_URL}/analysis/${uid}`,
		{
			params: { analysis_id: uid },
			headers: {
				"Ip-Address": ip,
			},
		}
	);
};

export const getAnalysis = async (pageConfig = null) => {
	if (!pageConfig) {
		pageConfig = {
			page: 1,
			page_size: 100,
		};
	}

	return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/analysis/`, {
		params: pageConfig,
	});
};

export const getWordlists = async () => {
	return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/wordlists/`);
};

export const getConfig = async () => {
	return await axios.get(
		`${process.env.REACT_APP_BASIC_API_URL}/analysis/configs/`
	);
};

export const startAnalysis = async (data) => {
	return await axios.post(
		`${process.env.REACT_APP_BASIC_API_URL}/analysis/start/`,
		data
	);
};

export const stopAnalysis = async (analysis_id) => {
	return await axios.post(
		`${process.env.REACT_APP_BASIC_API_URL}/analysis/${analysis_id}/stop/`
	);
};

export const startFurtherDomainAnalysis = async (uid, data) => {
	return await axios.post(
		`${process.env.REACT_APP_BASIC_API_URL}/analysis/${uid}/further/`,
		data
	);
};

export const getFurtherDomainAnalysis = async (uid) => {
	return await axios.get(
		`${process.env.REACT_APP_BASIC_API_URL}/analysis/${uid}/further/`,
		{
			params: {
				analysis_id: uid,
			},
		}
	);
};

export const getExportVariationsCsv = async (uid) => {
	return await axios.get(
		`${process.env.REACT_APP_BASIC_API_URL}/analysis/${uid}/variations/export/`,
		{
			responseType: "blob",
			headers: {
				Accept: "*/*",
			},
		}
	);
};

export const getExportAnalysisCsv = async (uid) => {
	return await axios.get(
		`${process.env.REACT_APP_BASIC_API_URL}/analysis/${uid}/export/`,
		{
			responseType: "blob",
			headers: {
				Accept: "*/*",
			},
		}
	);
};

export const startSetupMonitor = async (uid, data) => {
	return await axios.post(
		`${process.env.REACT_APP_BASIC_API_URL}/monitors/?analysis_id=${uid}`,
		data
	);
};

export const updateMonitor = async (uid, data) => {
	return await axios.patch(
		`${process.env.REACT_APP_BASIC_API_URL}/monitors/?analysis_id=${uid}`,
		data
	);
};

export const getDomainMonitor = async (uid) => {
	return await axios.get(
		`${process.env.REACT_APP_BASIC_API_URL}/monitors/?analysis_id=${uid}`
	);
};

export const getAllMonitors = async (pageConfig = null) => {

	if (!pageConfig) {
		pageConfig = {
			page: 1,
			page_size: 100,
		};
	}

	return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/monitors/`, {
		params: pageConfig,
	});
};

export const getAllTakeDowns = async () => {
	return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/takedown/`);
};

export const getAllAlerts = async (pageConfig) => {

	if (!pageConfig) {
		pageConfig = {
			page: 1,
			page_size: 100,
		};
	}

	return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/alerts/`, {
		params: pageConfig,
	});
};

export const startDomainTakeDown = async (data) => {
	return await axios.post(
		`${process.env.REACT_APP_BASIC_API_URL}/takedown/start/`,
		data
	);
};

export const getDomainTakeDown = async (uid) => {
	return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/takedown`);
};

export const updateDomainTakedown = async (uid, additionalNotes) => {
	return await axios.patch(`${process.env.REACT_APP_BASIC_API_URL}/takedown`, {
		additionalNotes: additionalNotes
	});
};

export const saveWordList = async (data) => {
	return await axios.post(
		`${process.env.REACT_APP_BASIC_API_URL}/wordlists/`,
		data
	);
};

export const startTakeDown = async (data) => {
	return await axios.post(
		`${process.env.REACT_APP_BASIC_API_URL}/takedown/start/`,
		data
	);
};

export const sendTakeDown = async (takedown_id, data) => {
	return await axios.post(
		`${process.env.REACT_APP_BASIC_API_URL}/takedown/${takedown_id}/send/`,
		data
	);
};

export const getPowerFile = async () => {
	return await axios.get(
		`${process.env.REACT_APP_BASIC_API_URL}/takedown/power-file/`,
		{
			responseType: "blob", // Ensure the response is treated as a Blob
		}
	);
};

export const deleteAnalysis = async (analysis_id) => {
	return await axios.delete(
		`${process.env.REACT_APP_BASIC_API_URL}/analysis/${analysis_id}`
	);
};

export const deleteMonitor = async (analysis_id, data) => {
	return await axios.delete(`${process.env.REACT_APP_BASIC_API_URL}/monitors`, {
		params: {
			analysis_id: analysis_id,
		},
		data: data,
	});
};

export const deleteTakeDown = async (takedown_id) => {
	return await axios.delete(`${process.env.REACT_APP_BASIC_API_URL}/takedown`, {
		params: {
			takedown_id: takedown_id,
		},
	});
};

export const getSingleMonitor = async (monitor_id) => {
	return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/monitors`, {
		params: {
			monitor_id: monitor_id,
		},
	});
};

export const getSingleMonitorByAnalysisId = async (analysis_id) => {
	return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/monitors`, {
		params: {
			analysis_id: analysis_id,
		},
	});
};

export const updateSingleMonitor = async (analysis_id, data) => {
    // If data is FormData, extract and restructure it
    if (data instanceof FormData) {
        const conditions = data.getAll('conditions');
        const monitor_ids = [data.get('monitor_id')].filter(Boolean);
        
        return await axios.patch(
            `${process.env.REACT_APP_BASIC_API_URL}/monitors`,
            { conditions, monitor_ids },
            {
                params: {
                    analysis_id: analysis_id,
                },
            }
        );
    }

    // If data is already in correct format, send as is
    return await axios.patch(
        `${process.env.REACT_APP_BASIC_API_URL}/monitors`,
        data,
        {
            params: {
                analysis_id: analysis_id,
            },
        }
    );
};

export const getSingleMonitorAlerts = async (monitor_id) => {
	return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/alerts`, {
		params: {
			monitor_id: monitor_id,
		},
	});
};

export const sendContact = async (data) => {
	return await axios.post(
		`${process.env.REACT_APP_BASIC_API_URL}/contact/`,
		data
	);
};

// Jan '25 Additions of new Scan Modal Features
// 1. logo
// Logo management endpoints
export const getLogos = async () => {
	return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/logos/`);
};

export const uploadLogo = async (formData) => {
	return await axios.post(
		`${process.env.REACT_APP_BASIC_API_URL}/logos/`,
		formData
	);
};
// remove delete logo
export const deleteLogo = async (id) => {
	return await axios.delete(
		`${process.env.REACT_APP_BASIC_API_URL}/logos/${id}/`
	);
};
///////
// 2. Branding
export const getBranding = async () => {
	return await axios.get(`${process.env.REACT_APP_BASIC_API_URL}/branding/`);
};

export const uploadBranding = async (brandingData) => {
	return await axios.post(
		`${process.env.REACT_APP_BASIC_API_URL}/branding/`,
		brandingData
	);
};

//////
// 3. POA
// Power of Attorney endpoints
export const uploadPowerOfAttorney = async (formData) => {
	return await axios.post(
		`${process.env.REACT_APP_BASIC_API_URL}/power-of-attorney/`,
		formData
	);
};
export const deletePowerOfAttorney = async (id) => {
	return await axios.delete(
		`${process.env.REACT_APP_BASIC_API_URL}/power-of-attorney/${id}/`
	);
};

export const getPowerOfAttorney = async () => {
	return await axios.get(
		`${process.env.REACT_APP_BASIC_API_URL}/power-of-attorney/`
	);
};

// end jan '25 new scan modal features

export const updateAnalysisSettings = async (analysisId, data) => {
	return await axios.patch(
		`${process.env.REACT_APP_BASIC_API_URL}/analysis/${analysisId}/`,
		data
	);
};


