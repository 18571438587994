import React from "react";

const Footer = () => {
	return (
		<footer>
			<div className="container">
				<div className="footer-content">
					<div className="footer-logo" style={{ padding: "0px" }}>
						<img src="/assets/images/online/logo.png" alt="Footer Logo" />
						<p>
							Spoofguard shields organizations from online scams, automating the
							entire process from domain monitoring to takedown enforcement.
						</p>
					</div>
					<div className="footer-links">
						<h3>Navigation</h3>
						<ul>
							<li>
								<a href="/">Home</a>
							</li>
							<li>
								<a href="/aboutus">About Us</a>
							</li>
							{/* <li><a href="#">Pricing</a></li> */}
							<li>
								<a href="/technology">Technology</a>
							</li>
						</ul>
					</div>
					<div className="footer-links">
						<h3>Resources</h3>
						<ul>
							<li>
								<a href="/register">Register</a>
							</li>
							<li>
								<a href="/login">Login</a>
							</li>
							{/* <li><a href="#">Blogs</a></li> */}
							<li>
								<a
									href="https://calendly.com/spoofguard"
									target="_blank"
									rel="noreferrer"
								>
									Book a Demo
								</a>
							</li>
						</ul>
					</div>

					<div className="footer-links">
						<h3>Other Solutions</h3>
						<ul>
							<li>
								<a
									href="https://darknetsearch.com/"
									target="_blank"
									rel="noreferrer"
								>
									Darknetsearch - Dark Web Monitoring
								</a>
							</li>
							<li>
								<a href="https://urlscore.ai/" target="_blank" rel="noreferrer">
									Urlscore - Browsing Risk Test
								</a>
							</li>
							{/* <li><a href="#">Blogs</a></li> */}
							<li>
								<a
									href="https://calendly.com/spoofguard"
									target="_blank"
									rel="noreferrer"
								>
									Book a Demo
								</a>
							</li>
						</ul>
					</div>
					<div className="footer-contact">
						<h3>Stay in touch</h3>
						<div className="contact-item">
							{/* <i className="fab fa-facebook-f icon-circlem"></i> */}
							<a
								href="https://www.linkedin.com/company/spoofguard/"
								target="_blank"
								rel="noreferrer"
							>
								<i className="fab fa-linkedin icon-circlem"></i>
							</a>
							<a
								href="https://x.com/spoofguard"
								target="_blank"
								rel="noreferrer"
							>
								<i className="fa-brands fa-x-twitter icon-circlem"></i>
							</a>
						</div>
					</div>
				</div>
			</div>

			<hr
				style={{
					borderTop: " 1px solid rgb(255 255 255)",
					width: "100%",
					opacity: "0.3",
					marginTop: "3rem",
				}}
			/>
			<div className="footer-bottom container">
				<p>Copyright © 2025 SpoofGuard. All Rights Reserved.</p>
			</div>
		</footer>
	);
};

export default Footer;
