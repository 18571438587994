import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { dashboardItems } from "../../data/dashboardData";

import arrowdown from "../../assets/images/icons/arrowdown.png";
import arrowup from "../../assets/images/icons/arrowup.png";
import menu from "../../assets/images/icons/menu.png";
import smalllogo from "../../assets/images/icons/smalllogo.png";
import logo from "../../assets/images/SpoofGuard-logo-black-mod.png";
import useraccount from "../../assets/images/user_account.png";
import { logout } from "../../actions/accountActions";
import { motion, AnimatePresence } from "framer-motion";
import { fetchConfig, setIsMenuOpen } from "../../slices/scan-slice";

import "react-responsive-modal/styles.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { MenuBook, Menu as MaterialMenu, ArrowForwardIos, ArrowBackIosNew } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const DashboardLayout = ({ children, title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ismemu = useSelector(state => state.scan.isMenuOpen)

  //const [ismemu, setMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);

  const handleClick = () => {
    //setMenu(!ismemu);
    dispatch(setIsMenuOpen(!ismemu))
  };

  const location = useLocation();
  const routeName = location.pathname;
  const extractedPart = routeName.split("/").pop();

  const [open, setopen] = useState(false);

  const onOpenModal = () => setopen(true);
  const onCloseModal = () => setopen(false);

  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      dispatch(logout());
      localStorage.clear();
      navigate("/");
    } catch (error) {
      enqueueSnackbar("Unable to logout", {
        variant: "error",
      });
    }
  };
  const handleNavigate = (id) => {
    if (id.startsWith('http')) {
      window.open(id, '_blank');
    } else {
      navigate(id);
      sessionStorage.clear();
    }
  };

  const toggleMenu = (id) => {
    setActiveMenu(activeMenu === id ? null : id);
  };

  return (
    <div className="">
      <div className="hidden md:block relative">
        <div
          style={{
            boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)"
          }}
          className={`bg-white h-[100vh] py-8 fixed ${!ismemu
            ? "w-[100vw] absolute md:fixed z-[90] md:w-[250px] px-4"
            : "w-[60px] md:w-[100px] px-1"
            }`}
        >
          <div
            className={`flex items-center ${!ismemu ? "justify-between" : "justify-center"
              }`}
          >
            {!ismemu ? (
              <img src={logo} className="h-[30px] lg:h-[40px]" alt="" />
            ) : (
              <>
                <img
                  src={smalllogo}
                  className="h-[30px] lg:h-[40px] cursor-pointer"
                  onClick={handleClick}
                  alt=""
                />
                <div className="custom-expand-arrow closed">
                  <ArrowForwardIos onClick={handleClick} />
                </div>

              </>

            )}
            <div className="">
              {!ismemu ? (
                <>
                  <div className="custom-expand-arrow">
                    <ArrowBackIosNew onClick={handleClick} />
                  </div>
                </>

              ) : null}
            </div>
          </div>
          <div className="pt-8  customModernMenuNav">
            {dashboardItems.map((item, index) => (
              <div key={index}>
                {ismemu
                  ? <Tooltip title={item.title} placement="right">
                    <div

                      className={`min-h-[50px] flex items-center rounded-xl cursor-pointer ${!ismemu ? "px-4" : "px-1 justify-center"
                        } ${extractedPart !== item.id ? "" : "bg-primary/20"}`}
                      onClick={() =>
                        item.title === "Take down Site" || item.title === "Alerts"
                          ? toggleMenu(item.id)
                          : handleNavigate(item.link)
                      }
                    >
                      <div
                        className={`flex gap-3 items-center ${extractedPart !== item.id ? "text-gray-950" : "text-gray-950"
                          }`}
                      >
                        {item.icon}
                        {!ismemu && <p>{item.title}</p>}
                        {item.subtitle.length > 0 &&
                          (item.title === "Take down Site" ||
                            item.title === "Alerts") && (
                            <div>
                              {activeMenu === item.id ? (
                                <img src={arrowup} alt="Collapse" />
                              ) : (
                                <img src={arrowdown} alt="Expand" />
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                  : <div

                    className={`min-h-[50px] flex items-center rounded-xl cursor-pointer ${!ismemu ? "px-4" : "px-1 justify-center"
                      } ${extractedPart !== item.id ? "" : "bg-primary/20"}`}
                    onClick={() =>
                      item.title === "Take down Site" || item.title === "Alerts"
                        ? toggleMenu(item.id)
                        : handleNavigate(item.link)
                    }
                  >
                    <div
                      className={`flex gap-3 items-center ${extractedPart !== item.id ? "text-gray-950" : "text-gray-950"
                        }`}
                    >
                      {item.icon}
                      {!ismemu && <p>{item.title}</p>}
                      {item.subtitle.length > 0 &&
                        (item.title === "Take down Site" ||
                          item.title === "Alerts") && (
                          <div>
                            {activeMenu === item.id ? (
                              <img src={arrowup} alt="Collapse" />
                            ) : (
                              <img src={arrowdown} alt="Expand" />
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                }

                <AnimatePresence>
                  {activeMenu === item.id && (
                    <motion.div
                      initial={{ height: 0 }}
                      animate={{ height: "auto" }}
                      exit={{ height: 0 }}
                      transition={{ duration: 0.3 }}
                      className="overflow-hidden"
                    >
                      {item.subtitle.map((subItem, subIndex) => (
                        <Link
                          key={subIndex}
                          to={subItem.link}
                          className="min-h-[40px] flex items-center text-white hover:bg-primary/20 px-4 transition-all duration-200 transform"
                          onClick={() => {
                            setActiveMenu(null);
                            sessionStorage.clear();
                          }}
                        >
                          {subItem.title}
                        </Link>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div
            className={`w-full min-h-[100vh] ${!ismemu ? "pl-[60px] md:pl-[250px]" : "pl-[60px] md:pl-[100px]"
              }`}
          >
            {children}
          </div>
        </div>
      </div>

      <div className="block md:hidden relative">
        <div className="fixed z-[999] h-[60px] bg-white w-full flex items-center justify-between px-4">
          {/* <img src={menu} className="w-[30px]" onClick={onOpenModal} alt="" /> */}
          <MaterialMenu onClick={onOpenModal} />

          <Menu
            menuButton={
              <MenuButton>
                <div className="flex items-center gap-3">
                  <img src={useraccount} alt="" />
                </div>
              </MenuButton>
            }
            transition
          >
            <MenuItem>
              <Link to="/">Back To Home</Link>
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
            <MenuItem>
              <Link to={`/dashboard/settings`}>Settings</Link>
            </MenuItem>
          </Menu>
        </div>
        <div>
          <div className={`w-full min-h-[100vh] pt-[60px]`}>{children}</div>
        </div>
        <Modal
          open={open}
          onClose={onCloseModal}
          showCloseIcon={false}
          classNames={{
            overlay: "customOverlay",
            modal: "dashboardlayout",
          }}
        >
          <div className={`bg-white h-[100vh] py-8 w-[100vw] px-4`}>
            <div className={`flex items-center justify-center`}>
              <img src={logo} className="h-[40px]" alt="" />
            </div>
            <div className="text-white pt-8 text-center">
              <p className="text-lg font-semibold">
                Welcome &nbsp;
                {!account.user ? null : <span>{account.user.first_name}</span>}
              </p>
              <p className="pt-2">{title}</p>
            </div>
            <div className="pt-8">
              {dashboardItems.map((item, index) => (
                <div key={index}>
                  <div
                    className={`min-h-[50px] flex items-center rounded-xl cursor-pointer px-4 ${extractedPart !== item.id ? "" : "bg-primary/20"
                      }`}
                    onClick={() =>
                      item.title === "Take down Site" || item.title === "Alerts"
                        ? toggleMenu(item.id)
                        : handleNavigate(item.link)
                    }
                  >
                    <div
                      className={`flex gap-3 items-center ${extractedPart !== item.id ? "text-gray-950" : " text-gray-950"
                        }`}
                    >
                      {item.icon}
                      <p>{item.title}</p>
                      {item.subtitle.length > 0 &&
                        (item.title === "Take down Site" ||
                          item.title === "Alerts") && (
                          <div>
                            {activeMenu === item.id ? (
                              <img src={arrowup} alt="Collapse" />
                            ) : (
                              <img src={arrowdown} alt="Expand" />
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                  {activeMenu === item.id &&
                    item.subtitle.length > 0 &&
                    (item.title === "Take down Site" ||
                      item.title === "Alerts") && (
                      <div className="pl-8">
                        {item.subtitle.map((subItem, subIndex) => (
                          <Link
                            key={subIndex}
                            to={subItem.link}
                            className="min-h-[40px] flex items-center text-white px-4 transition-transform duration-200 transform hover:scale-105 hover:bg-primary/20"
                            onClick={() => {
                              setActiveMenu(null);
                              sessionStorage.clear();
                            }}
                          >
                            {subItem.title}
                          </Link>
                        ))}
                      </div>
                    )}
                </div>
              ))}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default DashboardLayout;
