import React, { useState, useMemo, useEffect } from "react";
import {
	Modal,
	Box,
	Typography,
	TextField,
	Switch,
	FormControlLabel,
	Button,
	Grid,
	IconButton,
	Divider,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Tooltip,
	LinearProgress,
	Paper,
	//Chip, // Jan 25 - Commented out as not used - for cleanup
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import {
	addBrand,
	updatePOAFile,
	setSelectedLogo,
	updateAuthLetter,
	updateAvailableLogos,
	updateSelectedPOA,
	//updateBrand,  // Jan 25 - Commented out as not used - for cleanup
	//updateBrandLogo,  // Jan 25 - Commented out as not used - for cleanup
} from "../../slices/scan-slice";
import { saveFileToLocalStorage } from "../../utiles/general";
import {
	getPowerFile,
	getWordlists,
	saveWordList,
	startAnalysis,
	// Jan '25 Additions of new Scan Modal Features
	// 1. Logo
	// 1. Logo
	getLogos,
	uploadLogo,
	// 2. Branding Wordlists
	getBranding,
	uploadBranding,
	// 3. POA
	getPowerOfAttorney,
	uploadPowerOfAttorney,
	//deletePowerOfAttorney,
	// end jan '25 new scan modal features
} from "../../api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import /*InfoOutlined, // Jan 25 - Commented out as not used - for cleanup */
// PlusOneSharp,
//UploadFile,
"@mui/icons-material";
import { Info, PlusOneSharp, UploadFile } from "@mui/icons-material";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "80%",
	maxWidth: 900,
	maxHeight: "90vh",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	overflow: "auto",
};

const NewScanModal = ({ open, onClose }) => {
	const [enableAll, setEnableAll] = useState(false);
	const [moduleStates, setModuleStates] = useState({});
	const [selectedWordlist, setSelectedWordlist] = useState("");
	const [expandedGroup, setExpandedGroup] = useState("");
	const [wordlistEnabled, setWordlistEnabled] = useState(false);
	const [groupedModules, setGroupedModules] = useState([]);

	const [parsedWords, setParsedWords] = useState([]);
	const [wordlists, setWordlists] = useState([]);
	const [newWordlist, setNewWordlist] = useState(false);
	const [newWordlistFile, setNewWordlistFile] = useState(null);
	const [newWordlistName, setNewWordlistName] = useState("");
	const [wordlistsError, setWordlistsError] = useState(false);
	/*const [wordlistLoading, setWordlistLoading] = useState(false);  // Jan 25 - Commented out as not used - for cleanup*/

	const [manualWordlist, setManualWordlist] = useState("");
	const [publicSuffixes, setPublicSuffixes] = useState([]);

	const [domainError, setDomainError] = useState(false);

	const [loading, setLoading] = useState(false);

	const [brand, setBrand] = useState("");
	const [brandLogo, setBrandLogo] = useState(null);
	const [authLetterName, setAuthLetterName] = useState("");

	const [domain, setDomain] = useState("");
	const [
		customDomains /*setCustomDomains  // Jan 25 - Commented out as not used - for cleanup*/,
	] = useState([]);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { enqueueSnackbar } = useSnackbar();

	const modules = useSelector((state) => state?.scan?.config?.modules);
	const domainRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

	// Jan '25 Additions of new Scan Modal Features
	// 1. Logo
	// Add new state for logos
	const [availableLogos, setAvailableLogos] = useState([]);
	const [selectedLogoId, setSelectedLogoId] = useState("");
	const [logoUploading, setLogoUploading] = useState(false);
	//
	// Add new state variables
	const [logoName, setLogoName] = useState("");
	const [logoFile, setLogoFile] = useState(null);
	const [showLogoUploadForm, setShowLogoUploadForm] = useState(false);
	//
	///////
	// 2. Branding
	// Add new state for branding
	const [availableBranding, setAvailableBranding] = useState([]);
	const [selectedBrandingId, setSelectedBrandingId] = useState("");
	const [showBrandingForm, setShowBrandingForm] = useState(false);
	const [brandingName, setBrandingName] = useState("");
	const [brandingKeywords, setBrandingKeywords] = useState(["", "", ""]);
	const [keywordMatchType, setKeywordMatchType] = useState("and");

	//
	// 3. POA
	// Add POA state variables
	const [availablePOAs, setAvailablePOAs] = useState([]);
	const [selectedPOAId, setSelectedPOAId] = useState("");
	const [showPOAUploadForm, setShowPOAUploadForm] = useState(false);
	const [poaName, setPoaName] = useState("");
	const [poaFile, setPoaFile] = useState(null);
	const [poaUploading, setPoaUploading] = useState(false);

	// Add loading states for each feature
	const [logoListLoading, setLogoListLoading] = useState(false);

	// end jan '25 new scan modal features

	const categories = useMemo(
		() => [
			{
				title: "Domain Structure Modifications",
				modules: [
					"module_tld",
					"module_append_tld",
					"module_append_tld_variations",
					"module_tldvariation_addon_tld",
					"module_prepend",
					"module_prepend_number",
					"module_append_numbers_letters",
					"module_subdomain_add",
					"module_subdomain_letter_hyphen",
					"module_hyphenated_domains",
				],
			},
			{
				title: "Typographical Manipulations",
				modules: [
					"module_letter_swap",
					"module_levenshtein_typos",
					"module_omission",
					"module_vowel_swap",
					"module_double_characters",
					"module_misspellings",
					"module_replacement_letters",
					"replacement_letters_next_in_keyboard",
				],
			},
			{
				title: "Look-alike Techniques",
				modules: [
					"module_homograph_word_variations",
					"module_punycode_variations",
					"module_bit_squatting",
				],
			},
			{
				title: "Number-Based Variations",
				modules: [
					"module_prepend_numbers",
					"module_add_common_numbers",
					"module_numeral_swap",
				],
			},
			{
				title: "Word-Based Modifications",
				modules: ["module_common_word", "module_hyphen"],
			},
			{
				title: "Detection and Monitoring Modules",
				modules: [
					"module_new_domain_registrations",
					"module_kaduu_new_domain_registrations",
					"module_api_domain_search",
					"module_api_domain_search_ssl",
					"module_domain_in_directory",
					"module_dyndns",
					"wrong_sld",
					"module_missing_dashes",
				],
			},
		],
		[]
	);

	useEffect(() => {
		if (modules) {
			const filtered = modules?.map((module) => ({
				code: module.code,
				name: module.name,
				description: module.description,
			}));

			const grouped = groupModules(categories, filtered);

			setGroupedModules(grouped);
			setModuleStates(
				Object.fromEntries(
					grouped.flatMap((group) => group.modules).map((m) => [m.code, false])
				)
			);
		}
	}, [modules, categories]);

	useEffect(() => {
		if (!wordlists.length) {
			getWordlists()
				.then((response) => {
					setWordlists(response?.data);
				})
				.catch(() => {
					enqueueSnackbar("Unable to load wordlists", {
						variant: "error",
					});
				});
		}
	}, [enqueueSnackbar, wordlists]);

	useEffect(() => {
		const fetchPublicSuffixList = async () => {
			try {
				const response = await fetch(
					"https://publicsuffix.org/list/public_suffix_list.dat"
				);
				const text = await response.text();

				const suffixes = text
					.split("\n")
					.filter((line) => line && !line.startsWith("//"))
					.map((line) => line.trim());

				setPublicSuffixes(suffixes);
			} catch (error) {
				console.error("Failed to fetch public suffix list:", error);
			}
		};

		fetchPublicSuffixList();
	}, []);

	const groupModules = (categories, filteredmodules) => {
		return categories.map((category) => ({
			title: category.title,
			modules: category.modules
				.map((moduleCode) =>
					filteredmodules.find((module) => module.code === moduleCode)
				)
				.filter(Boolean),
		}));
	};

	const handleEnableAll = () => {
		setEnableAll(!enableAll);
		setModuleStates(
			Object.fromEntries(
				groupedModules
					?.flatMap((group) => group.modules)
					.map((m) => [m.code, !enableAll])
			)
		);
	};

	const handleModuleChange = (moduleName) => {
		setModuleStates((prev) => ({
			...prev,
			[moduleName]: !prev[moduleName],
		}));
	};

	const handleGroupEnableAll = (groupModules) => {
		const allEnabled = groupModules.every((m) => moduleStates[m.code]);
		setModuleStates((prev) => ({
			...prev,
			...Object.fromEntries(groupModules.map((m) => [m.code, !allEnabled])),
		}));
	};

	const handleWordlistChange = (event) => {
		setSelectedWordlist(event.target.value);
	};

	const handleAccordionChange =
		(panel, groupModules) => (event, isExpanded) => {
			if (
				event.target.tagName === "INPUT" ||
				event.target.tagName === "LABEL"
			) {
				return;
			}
			setExpandedGroup(isExpanded ? panel : "");
		};

	const handleWordlistToggle = () => {
		setWordlistEnabled(!wordlistEnabled);
		if (!wordlistEnabled) {
			setSelectedWordlist("");
			setNewWordlist(false);
		}
	};

	const getEnabledModulesCount = (groupModules) => {
		return groupModules.filter((m) => moduleStates[m.code]).length;
	};

	const handleFileUpload = async (event) => {
		const file = event.target.files[0];

		if (!file) {
			enqueueSnackbar("No file selected", {
				variant: "error",
			});
			return;
		}

		const isCSV = file.type === "text/csv";
		const isTXT = file.type === "text/plain";

		if (!isCSV && !isTXT) {
			enqueueSnackbar(
				"File type not supported. Please upload a .txt or .csv file.",
				{
					variant: "error",
				}
			);
			return;
		}

		const reader = new FileReader();

		reader.onload = (e) => {
			const fileContent = e.target.result;

			if (isTXT) {
				const words = fileContent
					.split("\n")
					.map((line) => line.trim())
					.filter(Boolean);
				setNewWordlistFile(file.name);
				setParsedWords(words);
				console.log(words);
			} else if (isCSV) {
				Papa.parse(fileContent, {
					header: false,
					skipEmptyLines: true,
					complete: (results) => {
						const words = results?.data?.flat().filter(Boolean);
						setNewWordlistFile(file.name);
						setParsedWords(words);
						console.log(words);
					},
					error: (error) => {
						console.error("Error parsing the CSV file:", error);
						enqueueSnackbar("Error parsing the file", {
							variant: "error",
						});
					},
				});
			}
		};

		reader.onerror = (error) => {
			console.error("Error reading the file:", error);
			enqueueSnackbar("Error reading the file", {
				variant: "error",
			});
		};

		reader.readAsText(file);
	};

	const handleDomainSubmit = async () => {
		if (!domainRegex.test(domain)) {
			setDomainError(true);
			enqueueSnackbar("Please enter a valid domain (e.g., example.com)", {
				variant: "error",
			});
			return false;
		} else {
			setDomainError(false);
		}

		if (wordlistEnabled && selectedWordlist === "") {
			setWordlistsError(true);
			enqueueSnackbar("Please select or upload a wordlist", {
				variant: "error",
			});
			return false;
		} else {
			setWordlistsError(false);
		}

		//Transform moduleStates object into array
		const selectedModules = Object.entries(moduleStates)
			.filter(([key, value]) => value)
			.map(([key, value]) => ({ module_name: key, enabled: value }));

		if (selectedModules.length === 0) {
			enqueueSnackbar("Please select some modules to start your scan", {
				variant: "error",
			});
			return false;
		}

		if (wordlistEnabled) {
			selectedModules.push({
				module_name: "module_domain_word_combination",
				enabled: true,
				wordlist: selectedWordlist,
			});
		}

		const data = {
			config: {},
			custom_domains: parsedWords,
			domain: domain,
			keywords: [],
			modules: selectedModules,
			spoofing_op: keywordMatchType,
		};

		if (selectedLogoId) {
			data.logo_id = selectedLogoId;
		}

		if (selectedBrandingId) {
			data.branding_id = selectedBrandingId;
		}

		if (selectedPOAId) {
			data.power_of_attorney_id = selectedPOAId;
		}

		setLoading(true);

		startAnalysis(data)
			.then((response) => {
				if (response?.status === 200 && response?.data) {
					navigate(
						`/dashboard/domain-analysis/${response?.data?.analysis_id}`,
						{ state: { new_scan: true, poll: true, data: response?.data } }
					);
				}
			})
			.catch((error) => {
				if (
					error?.response?.status === 400 &&
					error?.response?.data?.details ===
						"Warning: The module domain_word_combination was selected, but no valid custom keywords are defined"
				) {
					enqueueSnackbar(
						"Please select a wordlist for the Word Combination module",
						{
							variant: "error",
						}
					);
				} else {
					enqueueSnackbar("An error has occured", {
						variant: "error",
					});
				}

				setLoading(false);
			});
	};

	const handleSaveWordList = () => {
		if (!newWordlistName) {
			enqueueSnackbar("Please enter a name for your wordlist", {
				variant: "error",
			});
			return;
		}

		let manualWords = [];
		if (manualWordlist.length > 0) {
			manualWords = manualWordlist.split(", ").map((word) => word.trim());
		}

		const combinedWords = [...parsedWords, ...manualWords].filter(
			(word, index, self) => word && self.indexOf(word) === index
		); // Remove duplicates

		if (combinedWords.length === 0) {
			enqueueSnackbar("Please upload a wordlist file or enter words manually", {
				variant: "error",
			});
			return;
		}

		const wordlist = {
			name: newWordlistName,
			code: newWordlistName.replace(/\s+/g, "_"),
			words: combinedWords,
		};

		saveWordList(wordlist)
			.then((response) => {
				const data = response?.data;
				if (data?.existing_wordlist_code) {
					enqueueSnackbar(
						`A wordlist containing these words already exists. Please use ${data?.existing_wordlist_code}`,
						{ variant: "error" }
					);
				} else {
					setNewWordlist(false);
					setWordlists([]);
					setSelectedWordlist(data.code);
					enqueueSnackbar("Your wordlist has been created.", {
						variant: "success",
					});
				}
			})
			.catch((error) => {
				enqueueSnackbar("An error occurred while saving the wordlist.", {
					variant: "error",
				});
			});
	};

	const handleDomainInput = (e) => {
		let inputValue = e.target.value.trim();

		// Step 1: Remove "http://", "https://", and "www." if present
		inputValue = inputValue.replace(/^(https?:\/\/)?(www\.)?/, "");

		// Step 2: Extract the root domain using the public suffix list
		const domainParts = inputValue.split(".");
		let rootDomain = inputValue;

		if (domainParts.length > 1) {
			for (let i = 0; i < domainParts.length; i++) {
				const possibleSuffix = domainParts.slice(i).join(".");
				if (publicSuffixes.includes(possibleSuffix)) {
					const baseDomain = domainParts.slice(i - 1).join(".");
					if (baseDomain) {
						rootDomain = baseDomain;
					}
					break;
				}
			}
		}

		setDomain(rootDomain);
	};

	useEffect(() => {
		const fetchLogos = async () => {
			try {
				const response = await getLogos();
				setAvailableLogos(response.data);
				dispatch(updateAvailableLogos(response.data));
			} catch (error) {
				enqueueSnackbar("Unable to fetch logos", { variant: "error" });
			}
		};
		fetchLogos();
	}, [dispatch, enqueueSnackbar]);

	// Add logo handlers
	const handleLogoFileSelect = (e) => {
		const file = e.target.files[0];
		if (file) {
			setLogoFile(file);
		}
	};

	const handleLogoSelect = (logoId) => {
		setSelectedLogoId(logoId);
		dispatch(setSelectedLogo(logoId));
		const selectedLogo = availableLogos.find((logo) => logo.id === logoId);
		if (selectedLogo) {
			setBrandLogo(selectedLogo); // If you need to keep this functionality
		}
	};

	const handleLogoSave = async () => {
		if (!logoFile || !logoName) {
			enqueueSnackbar("Please provide both logo name and file", {
				variant: "error",
			});
			return;
		}
		setLogoUploading(true);
		try {
			const formData = new FormData();
			formData.append("logo", logoFile); // This field name must match API
			formData.append("name", logoName);
			await uploadLogo(formData);
			const response = await getLogos();
			setAvailableLogos(response.data);

			setSelectedLogoId(response.data.filter((x) => x.name === logoName)[0].id);

			setLogoFile(null);
			setLogoName("");
			setShowLogoUploadForm(false);
			enqueueSnackbar("Logo saved successfully", { variant: "success" });
		} catch (error) {
			console.error("Upload error:", error.response?.data);
			enqueueSnackbar(error.response?.data?.message || "Error saving logo", {
				variant: "error",
			});
		} finally {
			setLogoUploading(false);
		}
	};

	// 2. Branding
	useEffect(() => {
		const fetchBranding = async () => {
			try {
				const response = await getBranding();
				setAvailableBranding(response.data);
			} catch (error) {
				enqueueSnackbar("Unable to fetch branding lists", {
					variant: "error",
				});
			}
		};
		fetchBranding();
	}, [enqueueSnackbar]);

	const handleBrandingSelect = (brandingId) => {
		setSelectedBrandingId(brandingId);
		const selectedBranding = availableBranding.find((b) => b.id === brandingId);
		if (selectedBranding) {
			setBrand(selectedBranding.keywords.join(", "));
		}
	};

	const handleKeywordChange = (index, value) => {
		setBrandingKeywords((prev) => {
			const updated = [...prev];
			updated[index] = value;
			return updated;
		});
	};

	const handleBrandingSave = async () => {
		if (!brandingName || brandingKeywords.filter((k) => k).length === 0) {
			enqueueSnackbar("Please provide both name and keywords", {
				variant: "error",
			});
			return;
		}

		try {
			const data = {
				name: brandingName,
				keywords: brandingKeywords.filter((k) => k),
			};

			await uploadBranding(data);

			const response = await getBranding();
			setAvailableBranding(response.data);

			setSelectedBrandingId(
				response.data.filter((x) => x.name === brandingName)[0].id
			);
			setBrandingName("");
			setBrandingKeywords(["", "", ""]);
			setShowBrandingForm(false);

			enqueueSnackbar("Branding keywords saved successfully", {
				variant: "success",
			});
		} catch (error) {
			enqueueSnackbar("Error saving branding", { variant: "error" });
		}
	};

	useEffect(() => {
		const fetchPOAs = async () => {
			try {
				const response = await getPowerOfAttorney();
				setAvailablePOAs(response.data);
			} catch (error) {
				enqueueSnackbar("Unable to fetch power of attorney documents", {
					variant: "error",
				});
			}
		};
		fetchPOAs();
	}, [enqueueSnackbar]);

	const handlePOAFileSelect = (e) => {
		const file = e.target.files[0];
		if (file) {
			setPoaFile(file);
		}
	};

	const handlePOASelect = (poaId) => {
		setSelectedPOAId(poaId);
		const selectedPOA = availablePOAs.find((poa) => poa.id === poaId);
		if (selectedPOA) {
			setPoaFile(selectedPOA);
		}
	};

	const handlePOASave = async () => {
		if (!poaFile || !poaName) {
			enqueueSnackbar("Please provide both name and file", {
				variant: "error",
			});
			return;
		}
		setPoaUploading(true);
		try {
			const formData = new FormData();

			if (!(poaFile instanceof File)) {
				throw new Error("Invalid file object");
			}
			formData.append("signed_power_file", poaFile, poaFile.name); // Add filename as third parameter
			formData.append("name", poaName);

			console.log("POA File:", {
				name: poaFile.name,
				type: poaFile.type,
				size: poaFile.size,
			});

			const response = await uploadPowerOfAttorney(formData);
			if (response.data) {
				dispatch(updateSelectedPOA(response.data.id));
				dispatch(updatePOAFile(response.data));
				const poaResponse = await getPowerOfAttorney();
				setAvailablePOAs(poaResponse.data);
				setPoaFile(null);
				setPoaName("");
				setShowPOAUploadForm(false);
				enqueueSnackbar("Power of Attorney saved successfully", {
					variant: "success",
				});
			}
		} catch (error) {
			console.error("Upload error details:", {
				response: error.response?.data,
				status: error.response?.status,
			});
			const errorMessage =
				error.response?.data?.signed_power_file?.[0] ||
				error.response?.data?.detail ||
				"Error uploading file";
			enqueueSnackbar(errorMessage, { variant: "error" });
		} finally {
			setPoaUploading(false);
		}
	};

	return (
		<Modal open={open} onClose={onClose} aria-labelledby="new-scan-modal-title">
			<Box sx={style}>
				<Box
					display="flex"
					justifyContent="flex-end"
					alignItems="center"
					mb={3}
				>
					<IconButton onClick={onClose} size="small">
						<CloseIcon />
					</IconButton>
				</Box>

				<Grid container spacing={0}>
					<Paper sx={{ p: 3, mb: 3 }}>
						<Grid item xs={8}>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								mb={1}
							>
								<Typography
									id="new-scan-modal-title"
									textAlign={"left"}
									variant="h6"
									component="h2"
								>
									MANDATORY SETTINGS
								</Typography>
							</Box>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								mb={1}
							>
								<Typography
									id="new-scan-modal-title"
									textAlign={"left"}
									color="primary"
									variant="h6"
									component="h2"
								>
									Enter Your Domain
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={8}>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<Tooltip
											title="Enter your company's base domain (e.g., domain.com) to scan for active typosquatting. Avoid including subdomains like www.domain.com or sub.domain.com."
											arrow
										>
											<IconButton size="small" sx={{ mr: 1 }}>
												<InfoIcon fontSize="small" />
											</IconButton>
										</Tooltip>
										<TextField
											fullWidth
											label="Domain"
											value={domain}
											variant="outlined"
											onChange={(e) => setDomain(e.target.value)} // Update state on change
											onBlur={(e) => {
												handleDomainInput(e); // Sanitize domain on blur
											}}
											error={domainError}
										/>
									</Box>
								</Grid>
								<Grid item xs={4}>
									<Tooltip
										title="Our tool may not identify every possible variation of a typosquatted domain. If you are aware of any existing or past domain variations, you can upload them here. We will incorporate them into our scans to either detect when these domains become active or, if they are already active, enable monitoring. This includes tracking changes to their web services, identifying the use of your brand or logo, and assessing potential risks."
										arrow
									>
										<Button
											variant="outlined"
											component="label"
											startIcon={<CloudUploadIcon />}
											sx={{ height: "54px", width: "100%" }}
										>
											Upload Custom Domains
											<input
												type="file"
												hidden
												accept=".csv,.txt"
												onChange={(e) => handleFileUpload(e)}
											/>
										</Button>
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={8}>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								mt={3}
								mb={1}
							>
								<Typography
									id="new-scan-modal-title"
									textAlign={"left"}
									color="primary"
									variant="h6"
									component="h2"
								>
									Select Domain Detection Techniques
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Box display="flex" alignItems="center">
								<Tooltip
									title="SpoofGuard's core feature is its domain permutation modules, designed to detect typosquatting threats. Select modules below to process your domain and wordlist, generating typosquatting variations based on your preferred scan range."
									arrow
								>
									<InfoIcon fontSize="small" color="action" sx={{ mr: 1 }} />
								</Tooltip>

								<Box display="flex" alignItems="center">
									<Typography
										variant="h6"
										gutterBottom
										sx={{ m: 0, alignItems: "center" }}
									>
										Module Settings
									</Typography>
								</Box>
							</Box>

							<FormControlLabel
								control={
									<Switch checked={enableAll} onChange={handleEnableAll} />
								}
								label="Enable All"
								sx={{ mb: 2 }}
							/>

							{groupedModules?.map((group, index) => (
								<Accordion
									key={index}
									expanded={expandedGroup === `panel${index}`}
									onChange={handleAccordionChange(
										`panel${index}`,
										group.modules
									)}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`panel${index}bh-content`}
										id={`panel${index}bh-header`}
									>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												width: "100%",
												justifyContent: "space-between",
											}}
										>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													flexGrow: 1,
												}}
											>
												<Switch
													size="small"
													checked={group.modules.every(
														(m) => moduleStates[m.code]
													)}
													onChange={(e) => {
														e.stopPropagation();
														handleGroupEnableAll(group.modules);
													}}
													sx={{ mr: 1 }}
												/>
												<Typography>{group.title}</Typography>
											</Box>
											<Typography sx={{ color: "text.secondary" }}>
												{getEnabledModulesCount(group.modules)}/
												{group.modules.length} enabled
											</Typography>
										</Box>
									</AccordionSummary>
									<AccordionDetails>
										<Grid container spacing={2}>
											{group.modules.map((module) => (
												<Grid item xs={12} sm={6} md={4} key={module.name}>
													<Box sx={{ display: "flex", alignItems: "center" }}>
														<FormControlLabel
															control={
																<Switch
																	checked={moduleStates[module.code]}
																	onChange={() =>
																		handleModuleChange(module.code)
																	}
																/>
															}
															label={module.name}
														/>
														<Tooltip title={module.description}>
															<IconButton size="small">
																<InfoIcon fontSize="small" />
															</IconButton>
														</Tooltip>
													</Box>
												</Grid>
											))}
										</Grid>
									</AccordionDetails>
								</Accordion>
							))}
						</Grid>
					</Paper>
					<Paper sx={{ p: 3, mb: 3, width: "100%" }}>
						<Grid item xs={12}>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								mt={1}
								mb={1}
							>
								<Typography
									id="new-scan-modal-title"
									textAlign={"left"}
									variant="h6"
									component="h2"
								>
									OPTIONAL SETTINGS
								</Typography>
							</Box>
						</Grid>

						<Grid item xs={12}>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								mt={0}
								mb={0}
							>
								<Typography
									id="new-scan-modal-title"
									textAlign={"left"}
									color="primary"
									variant="h6"
									component="h2"
								>
									Enable Custom Detection Module "Wordlist"
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sx={{ mt: 0 }}>
							<Grid container spacing={2} sx={{ mt: 0 }} alignItems="center">
								<Grid item xs={12}>
									<FormControlLabel
										control={
											<Switch
												checked={wordlistEnabled}
												onChange={handleWordlistToggle}
												name="wordlist-toggle"
											/>
										}
										label="Enable Wordlist"
									/>
								</Grid>
								<Grid item xs={8}>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<Tooltip
											title={
												modules?.filter(
													(x) => x.code === "module_domain_word_combination"
												)[0].description
											}
											arrow
										>
											<IconButton size="small" sx={{ mr: 1 }}>
												<InfoIcon fontSize="small" />
											</IconButton>
										</Tooltip>
										<FormControl
											fullWidth
											disabled={!wordlistEnabled}
											error={wordlistsError}
										>
											<InputLabel id="wordlist-select-label">
												Wordlist
											</InputLabel>
											<Select
												labelId="wordlist-select-label"
												id="wordlist-select"
												value={selectedWordlist}
												onChange={handleWordlistChange}
												label="Wordlist"
												status="error"
											>
												{wordlists?.map((wordlist) => (
													<MenuItem key={wordlist.code} value={wordlist.code}>
														{wordlist.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Box>
								</Grid>
								<Grid item xs={4}>
									<Tooltip
										title="Upload .csv or .txt files. Each row should list one word (e.g., word1, word2). Download sample file"
										arrow
									>
										<Button
											variant="outlined"
											component="label"
											disabled={!wordlistEnabled}
											sx={{ height: "54px", width: "100%" }}
											onClick={() => setNewWordlist(true)}
										>
											Create new wordlist
										</Button>
									</Tooltip>
								</Grid>
								{newWordlist && wordlistEnabled && (
									<Grid item xs={12}>
										<Paper sx={{ p: 3 }}>
											<Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
												Create new wordlist
											</Typography>

											<TextField
												label="Wordlist Name"
												variant="outlined"
												fullWidth
												required
												value={newWordlistName}
												onChange={(e) => setNewWordlistName(e.target.value)}
												sx={{ mb: 2 }}
											/>

											<Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
												Option 1: Enter manually (Comma separated)
											</Typography>
											<TextField
												label="Keyword1, Keyword2, Keyword3..."
												variant="outlined"
												fullWidth
												required
												value={manualWordlist}
												onChange={(e) => setManualWordlist(e.target.value)}
												sx={{ mb: 2 }}
											/>

											<Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
												Option 2: Upload wordlist
											</Typography>
											<Button
												variant="outlined"
												component="label"
												fullWidth
												startIcon={<UploadFile />}
												sx={{ mb: 2 }}
											>
												Upload (CSV OR TXT FILE)
												<input
													type="file"
													accept=".csv, .txt"
													hidden
													onChange={handleFileUpload}
												/>
											</Button>

											{newWordlistFile && (
												<Typography
													variant="body2"
													color="success"
													sx={{ mb: 2 }}
												>
													{newWordlistFile} uploaded
												</Typography>
											)}

											<Grid item xs={12}>
												{loading && <LinearProgress />}

												<Box
													sx={{
														display: "flex",
														justifyContent: "flex-end",
														mt: 3,
													}}
												>
													<Button
														variant="contained"
														color="primary"
														onClick={() => handleSaveWordList()}
														sx={{ mr: 1 }}
														disabled={loading}
													>
														Save Wordlist
													</Button>
												</Box>
											</Grid>
										</Paper>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid container spacing={0} mt={2}>
							<Grid item xs={8}>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									mb={1}
								>
									<Typography
										id="new-scan-modal-title"
										textAlign={"left"}
										color="primary"
										variant="h6"
										component="h2"
									>
										Select Branding Keywords
									</Typography>
								</Box>
								<Box
									mb={1}
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<Tooltip
										title={
											"Select AND (default) to require all keywords or OR to match any keyword on the website."
										}
									>
										<Info
											sx={{
												color: "grey",
												fontSize: 20,
												marginRight: 1,
												marginLeft: "5px",
											}}
										/>
									</Tooltip>
									<span className="text-sm font-medium">AND</span>
									<Switch
										checked={keywordMatchType === "or"}
										onChange={(e) =>
											setKeywordMatchType(e.target.checked ? "or" : "and")
										}
										color="default"
										sx={{
											"& .MuiSwitch-thumb": {
												color: "grey",
											},
											"& .MuiSwitch-track": {
												backgroundColor: "lightgray",
												opacity: 1,
											},
											"& .MuiSwitch-switchBase.Mui-checked": {
												color: "grey",
											},
											"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
												{
													backgroundColor: "lightgray",
													opacity: 1,
												},
										}}
									/>
									<span className="text-sm font-medium">OR</span>
								</Box>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={8}>
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<Tooltip
										title="Enter your brand information for SpoofGuard to verify if your brand name appears as text or an image on discovered websites. You can include up to 3 brand keywords."
										arrow
									>
										<IconButton size="small" sx={{ mr: 1 }}>
											<InfoIcon fontSize="small" />
										</IconButton>
									</Tooltip>
									<FormControl fullWidth>
										<InputLabel>Select Branding Keywords</InputLabel>
										<Select
											value={selectedBrandingId}
											onChange={(e) => handleBrandingSelect(e.target.value)}
											label="Select Branding Keywords"
											disabled={!availableBranding.length}
										>
											{availableBranding?.map((branding) => (
												<MenuItem key={branding.id} value={branding.id}>
													{branding.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
							</Grid>
							<Grid item xs={4}>
								<Button
									variant="contained"
									onClick={() => setShowBrandingForm(true)}
									sx={{ minWidth: "100%", height: "54px" }}
								>
									Create Branding Keywords
								</Button>
							</Grid>
						</Grid>

						{showBrandingForm && (
							<Grid xs={12}>
								<Paper sx={{ mt: 2, p: 2 }}>
									<Box
										sx={{ display: "flex", flexDirection: "column", gap: 2 }}
									>
										<TextField
											fullWidth
											label="Branding List Name"
											value={brandingName}
											onChange={(e) => setBrandingName(e.target.value)}
										/>
										{brandingKeywords.map((keyword, index) => (
											<TextField
												key={index}
												fullWidth
												label={`Keyword ${index + 1}`}
												value={keyword}
												onChange={(e) =>
													handleKeywordChange(index, e.target.value)
												}
											/>
										))}
										<Box
											sx={{
												display: "flex",
												gap: 2,
												justifyContent: "flex-end",
											}}
										>
											<Button
												onClick={() => {
													setShowBrandingForm(false);
													setBrandingName("");
													setBrandingKeywords(["", "", ""]);
												}}
											>
												Cancel
											</Button>
											<Button variant="contained" onClick={handleBrandingSave}>
												Save
											</Button>
										</Box>
									</Box>
								</Paper>
							</Grid>
						)}

						<Grid container spacing={0} mt={2}>
							<Grid item xs={8}>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									mb={1}
								>
									<Typography
										id="new-scan-modal-title"
										textAlign={"left"}
										color="primary"
										variant="h6"
										component="h2"
									>
										Logo Selection
									</Typography>
								</Box>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={8}>
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<Tooltip
										title="Upload a company logo to verify if used on malicious websites"
										arrow
									>
										<IconButton size="small" sx={{ mr: 1 }}>
											<InfoIcon fontSize="small" />
										</IconButton>
									</Tooltip>
									<FormControl fullWidth>
										<InputLabel>Select Logo</InputLabel>
										<Select
											value={selectedLogoId}
											onChange={(e) => handleLogoSelect(e.target.value)}
											label="Select Logo"
											disabled={logoListLoading}
										>
											{logoListLoading ? (
												<MenuItem disabled>Loading logos...</MenuItem>
											) : (
												availableLogos.map((logo) => (
													<MenuItem key={logo.id} value={logo.id}>
														{logo.name}
													</MenuItem>
												))
											)}
										</Select>
									</FormControl>
								</Box>
							</Grid>
							<Grid item xs={4}>
								<Button
									variant="contained"
									onClick={() => setShowLogoUploadForm(true)}
									sx={{ minWidth: "100%", height: "54px" }}
								>
									Upload Logo
								</Button>
							</Grid>
						</Grid>

						{showLogoUploadForm && (
							<Grid xs={12}>
								<Paper sx={{ mt: 2, p: 2 }}>
									<Box
										sx={{ display: "flex", flexDirection: "column", gap: 2 }}
									>
										<TextField
											fullWidth
											label="Logo Name"
											value={logoName}
											onChange={(e) => setLogoName(e.target.value)}
										/>
										<Box sx={{ display: "flex", gap: 2 }}>
											<Button
												variant="outlined"
												component="label"
												startIcon={<CloudUploadIcon />}
												disabled={logoUploading}
											>
												{logoUploading ? "Uploading..." : "Choose Logo File"}
												<input
													type="file"
													F
													hidden
													accept="image/*"
													onChange={handleLogoFileSelect}
												/>
											</Button>
										</Box>
										{logoFile && (
											<Typography
												variant="body2"
												sx={{ alignSelf: "flex-start" }}
											>
												{logoFile.name}
											</Typography>
										)}
										<Box
											sx={{
												display: "flex",
												gap: 2,
												justifyContent: "flex-end",
											}}
										>
											<Button
												onClick={() => {
													setShowLogoUploadForm(false);
													setLogoFile(null);
													setLogoName("");
												}}
											>
												Cancel
											</Button>
											<Button
												variant="contained"
												onClick={handleLogoSave}
												disabled={!logoFile || !logoName || logoUploading}
											>
												Save
											</Button>
										</Box>
									</Box>
								</Paper>
							</Grid>
						)}

						{/** above is end end end of second new logo handle **/}
						{/* 3. new POA start Jan '25 Additions of new Scan Modal Features [POA] */}
						{/* Power of Attorney Section */}

						<Grid container spacing={0} mt={2}>
							<Grid item xs={8}>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									mb={1}
								>
									<Typography
										id="new-scan-modal-title"
										textAlign={"left"}
										color="primary"
										variant="h6"
										component="h2"
									>
										Sign and Upload Power of Attorney for Takedowns
									</Typography>
								</Box>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={8}>
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<Tooltip
										title="Upload a signed Power of Attorney document that authorizes SpoofGuard to send takedown requests on behalf your organization."
										arrow
									>
										<IconButton size="small" sx={{ mr: 1 }}>
											<InfoIcon fontSize="small" />
										</IconButton>
									</Tooltip>
									<FormControl fullWidth>
										<InputLabel>Select Power of Attorney</InputLabel>
										<Select
											value={selectedPOAId}
											onChange={(e) => handlePOASelect(e.target.value)}
											label="Select Power of Attorney"
											disabled={!availablePOAs.length}
										>
											{availablePOAs.map((poa) => (
												<MenuItem key={poa.id} value={poa.id}>
													{poa.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
							</Grid>
							<Grid item xs={4}>
								<Button
									variant="contained"
									onClick={() => setShowPOAUploadForm(true)}
									sx={{ minWidth: "100%", height: "54px" }}
								>
									Upload Power of Attorney
								</Button>
							</Grid>
						</Grid>

						{showPOAUploadForm && (
							<Grid xs={12}>
								<Paper sx={{ mt: 2, p: 2 }}>
									<Box
										sx={{ display: "flex", flexDirection: "column", gap: 2 }}
									>
										<TextField
											fullWidth
											label="Power of Attorney Name"
											value={poaName}
											onChange={(e) => setPoaName(e.target.value)}
										/>
										<Box sx={{ display: "flex", gap: 2 }}>
											<Button
												variant="outlined"
												component="label"
												startIcon={<CloudUploadIcon />}
												disabled={poaUploading}
											>
												{poaUploading ? "Uploading..." : "Choose File"}
												<input
													type="file"
													hidden
													accept=".pdf,.doc,.docx,.odt"
													onChange={handlePOAFileSelect}
												/>
											</Button>
											<Typography
												variant="body2"
												color="primary"
												sx={{
													cursor: "pointer",
													"&:hover": {
														textDecoration: "underline",
													},
													width: "fit-content",
													display: "flex",
													alignItems: "center",
												}}
												onClick={() => {
													getPowerFile()
														.then((response) => {
															const url = window.URL.createObjectURL(
																new Blob([response.data])
															);
															const link = document.createElement("a");
															link.href = url;
															link.setAttribute(
																"download",
																"authorization_letter.odt"
															);
															document.body.appendChild(link);
															link.click();

															enqueueSnackbar("Powerfile downloaded", {
																variant: "success",
															});
														})
														.catch(() => {
															enqueueSnackbar("Unable to download powerfile", {
																variant: "error",
															});
														});
												}}
											>
												Download sample file
											</Typography>
										</Box>
										{poaFile && (
											<Typography
												variant="body2"
												sx={{ alignSelf: "flex-start" }}
											>
												{poaFile.name}
											</Typography>
										)}
										<Box
											sx={{
												display: "flex",
												gap: 2,
												justifyContent: "flex-end",
											}}
										>
											<Button
												onClick={() => {
													setShowPOAUploadForm(false);
													setPoaFile(null);
													setPoaName("");
												}}
											>
												Cancel
											</Button>
											<Button
												variant="contained"
												onClick={handlePOASave}
												disabled={!poaFile || !poaName || poaUploading}
											>
												Save
											</Button>
										</Box>
									</Box>
								</Paper>
							</Grid>
						)}
					</Paper>

					<Grid item xs={12}>
						<Divider sx={{ my: 2 }} />
					</Grid>

					<Grid item xs={12}>
						{loading && (
							<>
								<Typography variant="body2" align="center" p={1}>
									Initializing the domain scanning process. You will be
									automatically redirected to the analysis results shortly...
								</Typography>
								<LinearProgress />
							</>
						)}

						<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => handleDomainSubmit()}
								sx={{ mr: 1 }}
								disabled={loading}
							>
								Start scan
							</Button>
							<Button variant="outlined" onClick={onClose}>
								Cancel
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
};

export default NewScanModal;
