import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
	Box,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
	TextField,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

// Mui Icons  and Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
	getWordlists,
	saveWordList,
	getLogos,
	uploadLogo,
	getBranding,
	uploadBranding,
	getPowerOfAttorney,
	uploadPowerOfAttorney,
	getPowerFile,
	deletePowerOfAttorney,
} from "../../../../api"; // Updated path
import {
	//setPOAFile,
	//setSelectedLogo,
	//setSelectedPOA,
	//updateSelectedPOA,
	updatePOAFile,
} from "../../../../slices/scan-slice"; // Updated path

import Papa from "papaparse";

const AssetManagement = () => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [expandedPanel, setExpandedPanel] = useState(false);

	// Wordlists state
	const [wordlists, setWordlists] = useState([]);
	const [newWordlistName, setNewWordlistName] = useState("");
	const [manualWordlist, setManualWordlist] = useState("");
	//const [wordlistFile, setWordlistFile] = useState(null);

	// Logo state
	const [availableLogos, setAvailableLogos] = useState([]);
	const [logoName, setLogoName] = useState("");
	const [logoFile, setLogoFile] = useState(null);
	const [logoUploading, setLogoUploading] = useState(false);
	const [uploadLogoOpen, setUploadLogoOpen] = useState(false);
	const [selectedLogo, setSelectedLogo] = useState(null);

	// Branding state
	const [availableBranding, setAvailableBranding] = useState([]);
	const [brandingName, setBrandingName] = useState("");
	const [brandingKeywords, setBrandingKeywords] = useState(["", "", ""]);
	const [showBrandingForm, setShowBrandingForm] = useState(false);
	const [editingBranding, setEditingBranding] = useState(null);

	// POA state
	const [availablePOAs, setAvailablePOAs] = useState([]);
	const [poaName, setPoaName] = useState("");
	const [poaFile, setPoaFile] = useState(null);
	const [poaUploading, setPoaUploading] = useState(false);
	const [uploadPoaOpen, setUploadPoaOpen] = useState(false);

	// wordlist
	const [createWordlistOpen, setCreateWordlistOpen] = useState(false);

	const fileInputRef = useRef(null);
	const [editWordlistOpen, setEditWordlistOpen] = useState(false);

	const [editingWordlist, setEditingWordlist] = useState(null);
	const [editBrandingOpen, setEditBrandingOpen] = useState(false);

	// Load existing data
	/*
	useEffect(() => {
		const fetchData = async () => {
			try {
				const [wordlistsRes, logosRes, brandingRes, poaRes] = await Promise.all(
					[getWordlists(), getLogos(), getBranding(), getPowerOfAttorney()]
				);

				setWordlists(wordlistsRes.data || []);
				setAvailableLogos(logosRes.data || []);
				setAvailableBranding(brandingRes.data || []);
				setAvailablePOAs(poaRes.data || []);
			} catch (error) {
				enqueueSnackbar("Error loading assets", { variant: "error" });
			}
		};
		fetchData();
	}, [enqueueSnackbar]);
*/

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [wordlistsRes, logosRes, brandingRes, poaRes] = await Promise.all(
					[getWordlists(), getLogos(), getBranding(), getPowerOfAttorney()]
				);

				const sortedWordlists = wordlistsRes.data?.sort((a, b) => {
					return new Date(b.created_at) - new Date(a.created_at);
				});

				const sortedLogos = logosRes.data?.sort((a, b) => {
					return new Date(b.created_at) - new Date(a.created_at);
				});

				const sortedBranding = brandingRes.data?.sort((a, b) => {
					return new Date(b.created_at) - new Date(a.created_at);
				});

				const sortedPOAs = poaRes.data?.sort((a, b) => {
					return new Date(b.created_at) - new Date(a.created_at);
				});

				setWordlists(sortedWordlists || []);
				setAvailableLogos(sortedLogos || []);
				setAvailableBranding(sortedBranding || []);
				//setAvailablePOAs(poaRes.data || []);
				setAvailablePOAs(sortedPOAs || []);
			} catch (error) {
				enqueueSnackbar("Error loading assets", { variant: "error" });
			}
		};
		fetchData();
	}, [enqueueSnackbar]);

	const handleAccordionChange = (panel) => (event, isExpanded) => {
		setExpandedPanel(isExpanded ? panel : false);
	};

	// Handlers for wordlists

	const handleWordlistSave = async () => {
		if (!newWordlistName) {
			enqueueSnackbar("Please enter a wordlist name", { variant: "error" });
			return;
		}

		const words = manualWordlist
			.split(",")
			.map((word) => word.trim())
			.filter(Boolean);
		if (words.length === 0) {
			enqueueSnackbar("Please enter at least one word", { variant: "error" });
			return;
		}

		try {
			const code = newWordlistName
				.toLowerCase()
				.replace(/[^a-z0-9]/g, "_")
				.replace(/_+/g, "_")
				.replace(/^_|_$/g, "");

			const response = await saveWordList({
				name: newWordlistName,
				code: code,
				words: words,
			});

			if (response?.data?.existing_wordlist_code) {
				enqueueSnackbar(
					`A wordlist with name "${newWordlistName}" already exists`,
					{ variant: "error" }
				);
				return;
			}

			const listResponse = await getWordlists();
			const sortedWordlists = listResponse.data.sort((a, b) => {
				return new Date(b.created_at) - new Date(a.created_at);
			});

			setWordlists(sortedWordlists);
			setNewWordlistName("");
			setManualWordlist("");
			setCreateWordlistOpen(false);

			enqueueSnackbar("Wordlist created successfully", { variant: "success" });
		} catch (error) {
			enqueueSnackbar("Error creating wordlist", { variant: "error" });
		}
	};

	const handleLogoUploadClick = () => {
		setSelectedLogo(null);
		setLogoName("");
		setLogoFile(null);
		setUploadLogoOpen(true);
	};

	const handleLogoSave = async () => {
		if (!logoFile || !logoName) {
			enqueueSnackbar("Please provide both logo name and file", {
				variant: "error",
			});
			return;
		}

		setLogoUploading(true);
		try {
			const formData = new FormData();
			formData.append("logo", logoFile);
			formData.append("name", logoName);

			await uploadLogo(formData);
			const response = await getLogos();
			const sortedLogos = response.data.sort((a, b) => {
				return new Date(b.created_at) - new Date(a.created_at);
			});
			setAvailableLogos(sortedLogos);
			setLogoFile(null);
			setLogoName("");
			setLogoUploading(false);
			enqueueSnackbar("Logo saved successfully", { variant: "success" });
		} catch (error) {
			if (error.response?.data?.details) {
				enqueueSnackbar(error.response.data.details[0], { variant: "error" });
			} else {
				enqueueSnackbar("Error saving logo", { variant: "error" });
			}
		} finally {
			setLogoUploading(false);
		}
	};

	const handleDeleteLogo = async (logoId) => {
		try {
			await axios.delete(
				`${process.env.REACT_APP_BASIC_API_URL}/logos/${logoId}/`
			);
			//const response = await getLogos();
			const response = await getLogos();
			const sortedLogos = response.data.sort((a, b) => {
				return new Date(b.created_at) - new Date(a.created_at);
			});
			setAvailableLogos(sortedLogos);
			//setAvailableLogos(response.data);
			enqueueSnackbar("Logo deleted successfully", { variant: "success" });
		} catch (error) {
			enqueueSnackbar("Error deleting logo", { variant: "error" });
		}
	};

	// Handlers for branding

	const handleBrandingSave = async () => {
		const keywords = brandingKeywords.filter((k) => k);
		if (!brandingName || keywords.length === 0) {
			enqueueSnackbar("Please provide both name and keywords", {
				variant: "error",
			});
			return;
		}

		try {
			await uploadBranding({
				name: brandingName,
				keywords: keywords,
			});

			const response = await getBranding();
			const sortedBranding = response.data.sort((a, b) => {
				return new Date(b.created_at) - new Date(a.created_at);
			});
			setAvailableBranding(sortedBranding);
			setBrandingName("");
			setBrandingKeywords(["", "", ""]);
			enqueueSnackbar("Brand keywords saved successfully", {
				variant: "success",
			});
		} catch (error) {
			if (error.response?.data?.details) {
				enqueueSnackbar(error.response.data.details[0], { variant: "error" });
			} else {
				enqueueSnackbar("Error saving brand keywords", { variant: "error" });
			}
		}
	};

	const handleBrandingEditSave = async () => {
		if (!brandingName || !brandingKeywords.some((k) => k)) {
			enqueueSnackbar("Please provide both name and keywords", {
				variant: "error",
			});
			return;
		}

		try {
			// Use PATCH for editing
			await axios.patch(
				`${process.env.REACT_APP_BASIC_API_URL}/branding/${editingBranding.id}/`,
				{
					name: brandingName,
					keywords: brandingKeywords.filter((k) => k),
				}
			);

			const response = await getBranding();
			const sortedBranding = response.data.sort((a, b) => {
				return new Date(b.created_at) - new Date(a.created_at);
			});
			setAvailableBranding(sortedBranding);

			// Reset form
			setBrandingName("");
			setBrandingKeywords(["", "", ""]);
			setEditingBranding(null);
			setEditBrandingOpen(false);

			enqueueSnackbar("Brand keywords updated successfully", {
				variant: "success",
			});
		} catch (error) {
			enqueueSnackbar("Error updating brand keywords", { variant: "error" });
		}
	};

	const handleEditBranding = (brand) => {
		setEditingBranding(brand);
		setBrandingName(brand.name);
		setBrandingKeywords(
			brand.keywords.concat(Array(3 - brand.keywords.length).fill(""))
		);
		setEditBrandingOpen(true);
	};

	const handleDeleteBranding = async (brandingId) => {
		try {
			await axios.delete(
				`${process.env.REACT_APP_BASIC_API_URL}/branding/${brandingId}/`
			);
			//const response = await getBranding();
			const response = await getBranding();
			const sortedBranding = response.data.sort((a, b) => {
				return new Date(b.created_at) - new Date(a.created_at);
			});
			setAvailableBranding(sortedBranding);
			//setAvailableBranding(response.data);
			enqueueSnackbar("Brand keywords deleted successfully", {
				variant: "success",
			});
		} catch (error) {
			enqueueSnackbar("Error deleting brand keywords", { variant: "error" });
		}
	};

	// Handlers for POA
	/*
	const handleDownloadSample = async () => {
		try {
			const response = await getPowerFile();
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "poa_sample.pdf");
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			enqueueSnackbar("Sample file downloaded successfully", {
				variant: "success",
			});
		} catch (error) {
			enqueueSnackbar("Error downloading sample file", { variant: "error" });
		}
	};
    */

	const handleDeletePOA = async (poaId) => {
		try {
			await deletePowerOfAttorney(poaId);
			const response = await getPowerOfAttorney();
			setAvailablePOAs(response.data);
			enqueueSnackbar("Power of Attorney deleted successfully", {
				variant: "success",
			});
		} catch (error) {
			enqueueSnackbar("Error deleting Power of Attorney", {
				variant: "error",
			});
		}
	};

	const handlePOASave = async () => {
		if (!poaFile || !poaName) {
			enqueueSnackbar("Please provide both name and file", {
				variant: "error",
			});
			return;
		}

		setPoaUploading(true);
		try {
			const formData = new FormData();
			formData.append("signed_power_file", poaFile);
			formData.append("name", poaName);

			const response = await uploadPowerOfAttorney(formData);
			if (response.data) {
				dispatch(updatePOAFile(response.data.id));
				const poaResponse = await getPowerOfAttorney();
				const sortedPOAs = poaResponse.data.sort((a, b) => {
					return new Date(b.created_at) - new Date(a.created_at);
				});
				setAvailablePOAs(sortedPOAs);
				setPoaFile(null);
				setPoaName("");
				setUploadPoaOpen(false);
				enqueueSnackbar("Power of Attorney saved successfully", {
					variant: "success",
				});
			}
		} catch (error) {
			if (error.response?.data?.details) {
				enqueueSnackbar(error.response.data.details[0], { variant: "error" });
			} else if (error.response?.data?.name) {
				enqueueSnackbar(
					`A Power of Attorney with name "${poaName}" already exists`,
					{ variant: "error" }
				);
			} else {
				const errorMessage =
					error.response?.data?.message || "Error uploading file";
				enqueueSnackbar(errorMessage, { variant: "error" });
			}
		} finally {
			setPoaUploading(false);
		}
	};

	// Function to handle file upload (reused from new-scan-modal)

	const [uploadWordlistOpen, setUploadWordlistOpen] = useState(false);
	const [uploadedFile, setUploadedFile] = useState(null);

	const handleFileUpload = (event) => {
		const file = event.target.files[0];
		if (!file) {
			enqueueSnackbar("No file selected", { variant: "error" });
			return;
		}

		const isCSV = file.type === "text/csv";
		const isTXT = file.type === "text/plain";

		if (!isCSV && !isTXT) {
			enqueueSnackbar(
				"File type not supported. Please upload a .txt or .csv file.",
				{
					variant: "error",
				}
			);
			return;
		}

		setUploadedFile(file);
		setUploadWordlistOpen(true);
	};

	// Add new handler for processing the upload with name

	const handleWordlistUploadSave = async () => {
		if (!uploadedFile || !newWordlistName) {
			enqueueSnackbar("Please provide both name and file", {
				variant: "error",
			});
			return;
		}

		const reader = new FileReader();
		reader.onload = async (e) => {
			const fileContent = e.target.result;
			let words = [];

			if (uploadedFile.type === "text/plain") {
				words = fileContent
					.split("\n")
					.map((line) => line.trim())
					.filter(Boolean);
			} else {
				Papa.parse(fileContent, {
					header: false,
					skipEmptyLines: true,
					complete: (results) => {
						words = results?.data?.flat().filter(Boolean);
					},
				});
			}

			try {
				const code = newWordlistName
					.toLowerCase()
					.replace(/[^a-z0-9]/g, "_")
					.replace(/_+/g, "_")
					.replace(/^_|_$/g, "");

				const response = await saveWordList({
					name: newWordlistName,
					code: code,
					words: words,
				});

				if (response?.data?.existing_wordlist_code) {
					enqueueSnackbar(
						`A wordlist with name "${newWordlistName}" already exists`,
						{ variant: "error" }
					);
					return;
				}

				const listResponse = await getWordlists();
				const sortedWordlists = listResponse.data.sort((a, b) => {
					return new Date(b.created_at) - new Date(a.created_at);
				});
				setWordlists(sortedWordlists);
				setNewWordlistName("");
				setUploadedFile(null);
				setUploadWordlistOpen(false);

				enqueueSnackbar("Wordlist uploaded successfully", {
					variant: "success",
				});
			} catch (error) {
				enqueueSnackbar("Error uploading wordlist", { variant: "error" });
			}
		};
		reader.readAsText(uploadedFile);
	};

	const handleEditWordlist = (wordlist) => {
		setEditingWordlist(wordlist);
		setNewWordlistName(wordlist.name);
		setManualWordlist(wordlist.words.join(", "));
		setEditWordlistOpen(true); // Use new edit modal instead of create modal
	};

	// Add edit save handler

	const handleWordlistEditSave = async () => {
		if (!newWordlistName) {
			enqueueSnackbar("Please enter a wordlist name", { variant: "error" });
			return;
		}

		const words = manualWordlist
			.split(",")
			.map((word) => word.trim())
			.filter(Boolean);

		if (words.length === 0) {
			enqueueSnackbar("Please enter at least one word", { variant: "error" });
			return;
		}

		try {
			await axios.patch(
				`${process.env.REACT_APP_BASIC_API_URL}/wordlists/${editingWordlist.id}/`,
				{
					name: newWordlistName,
					code: editingWordlist.code,
					words: words,
				}
			);

			const response = await getWordlists();
			const sortedWordlists = response.data.sort((a, b) => {
				return new Date(b.created_at) - new Date(a.created_at);
			});
			setWordlists(sortedWordlists);

			setNewWordlistName("");
			setManualWordlist("");
			setEditingWordlist(null);
			setEditWordlistOpen(false);

			enqueueSnackbar("Wordlist updated successfully", { variant: "success" });
		} catch (error) {
			enqueueSnackbar("Error updating wordlist", { variant: "error" });
		}
	};

	const handleDeleteWordlist = async (wordlistId) => {
		try {
			// Make API call to delete wordlist
			await axios.delete(
				`${process.env.REACT_APP_BASIC_API_URL}/wordlists/${wordlistId}/`
			);

			// Refresh wordlists after deletion
			//const response = await getWordlists();
			const response = await getWordlists();
			const sortedWordlists = response.data.sort((a, b) => {
				return new Date(b.created_at) - new Date(a.created_at);
			});
			setWordlists(sortedWordlists);
			//setWordlists(response.data);

			enqueueSnackbar("Wordlist deleted successfully", { variant: "success" });
		} catch (error) {
			enqueueSnackbar("Error deleting wordlist", { variant: "error" });
		}
	};

	return (
		<Box className="flex flex-col gap-4 p-4">
			<Typography variant="h6" className="mb-4">
				Asset Management
			</Typography>

			<Accordion
				expanded={expandedPanel === "wordlists"}
				onChange={handleAccordionChange("wordlists")}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="subtitle1">Custom Wordlists</Typography>
				</AccordionSummary>

				<AccordionDetails>
					<Box>
						<p className="text-sm md:text-base text-grey py-4">
							Combines your domain with additional relevant words or parts of
							words related to your brand to create new plausible domain
							variations, e.g., example-bank.com, example-finance.com
						</p>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								gap: 2,
								mb: 4,
							}}
						>
							<Button
								variant="outlined"
								startIcon={<AddIcon />}
								onClick={() => setCreateWordlistOpen(true)}
							>
								Create a Wordlist
							</Button>

							<Button
								variant="contained"
								startIcon={<CloudUploadIcon />}
								onClick={() => fileInputRef.current?.click()}
							>
								Upload Wordlist
							</Button>
							<input
								type="file"
								hidden
								ref={fileInputRef}
								accept=".txt,.csv"
								onChange={handleFileUpload}
							/>
						</Box>

						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Total Words</TableCell>
										<TableCell align="right">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{wordlists.length > 0 ? (
										wordlists.map((list) => (
											<TableRow key={list.id}>
												<TableCell>{list.name}</TableCell>
												<TableCell>{list.words?.length || 0}</TableCell>
												<TableCell align="right">
													<IconButton
														size="small"
														onClick={() => handleEditWordlist(list)}
													>
														<EditIcon />
													</IconButton>
													<IconButton
														size="small"
														onClick={() => handleDeleteWordlist(list.id)}
													>
														<DeleteIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell colSpan={3} align="center">
												No wordlists found
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</AccordionDetails>
				{/**
				<Dialog
					open={createWordlistOpen}
					onClose={() => setCreateWordlistOpen(false)}
					maxWidth="sm"
					fullWidth
				>
					<DialogTitle>Create New Wordlist</DialogTitle>
					<DialogContent>
						<TextField
							fullWidth
							label="Wordlist Name"
							value={newWordlistName}
							onChange={(e) => setNewWordlistName(e.target.value)}
							sx={{ mb: 3, mt: 2 }}
						/>
						<TextField
							fullWidth
							label="Enter words (comma-separated)"
							multiline
							rows={4}
							value={manualWordlist}
							onChange={(e) => setManualWordlist(e.target.value)}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setCreateWordlistOpen(false)}>Cancel</Button>
						<Button
							variant="contained"
							onClick={() => {
								handleWordlistSave();
								setCreateWordlistOpen(false);
							}}
						>
							Save Wordlist
						</Button>
					</DialogActions>
				</Dialog> */}
				{/** uplad wordlist dial 2/2 theres 2 and a upload 1 */}

				<Dialog
					open={createWordlistOpen}
					onClose={() => {
						setCreateWordlistOpen(false);
						setEditingWordlist(null);
						setNewWordlistName("");
						setManualWordlist("");
					}}
					maxWidth="sm"
					fullWidth
				>
					<DialogTitle>"Create New Wordlist"</DialogTitle>
					<DialogContent>
						<TextField
							fullWidth
							label="Wordlist Name"
							value={newWordlistName}
							onChange={(e) => setNewWordlistName(e.target.value)}
							sx={{ mb: 3, mt: 2 }}
						/>
						<TextField
							fullWidth
							label="Enter words (comma-separated)"
							multiline
							rows={4}
							value={manualWordlist}
							onChange={(e) => setManualWordlist(e.target.value)}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setCreateWordlistOpen(false)}>Cancel</Button>
						<Button
							variant="contained"
							onClick={() => {
								handleWordlistSave();
								setCreateWordlistOpen(false);
							}}
						>
							Save Wordlist
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={editWordlistOpen}
					onClose={() => {
						setEditWordlistOpen(false);
						setEditingWordlist(null);
						setNewWordlistName("");
						setManualWordlist("");
					}}
					maxWidth="sm"
					fullWidth
				>
					<DialogTitle>Edit Wordlist</DialogTitle>
					<DialogContent>
						<TextField
							fullWidth
							label="Wordlist Name"
							value={newWordlistName}
							onChange={(e) => setNewWordlistName(e.target.value)}
							sx={{ mb: 3, mt: 2 }}
						/>
						<TextField
							fullWidth
							label="Enter words (comma-separated)"
							multiline
							rows={4}
							value={manualWordlist}
							onChange={(e) => setManualWordlist(e.target.value)}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								setEditWordlistOpen(false);
								setEditingWordlist(null);
								setNewWordlistName("");
								setManualWordlist("");
							}}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							onClick={handleWordlistEditSave}
							disabled={!newWordlistName || !manualWordlist}
						>
							Save Changes
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={uploadWordlistOpen}
					onClose={() => {
						setUploadWordlistOpen(false);
						setNewWordlistName("");
						setUploadedFile(null);
					}}
					maxWidth="sm"
					fullWidth
				>
					<DialogTitle>Upload Wordlist</DialogTitle>
					<DialogContent>
						<TextField
							fullWidth
							label="Wordlist Name"
							value={newWordlistName}
							onChange={(e) => setNewWordlistName(e.target.value)}
							sx={{ mb: 3, mt: 2 }}
						/>
						{uploadedFile && (
							<Typography variant="body2" className="mb-4">
								Selected file: {uploadedFile.name}
							</Typography>
						)}
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								setUploadWordlistOpen(false);
								setNewWordlistName("");
								setUploadedFile(null);
							}}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							onClick={handleWordlistUploadSave}
							disabled={!uploadedFile || !newWordlistName}
						>
							Upload
						</Button>
					</DialogActions>
				</Dialog>
			</Accordion>

			<Accordion
				expanded={expandedPanel === "logos"}
				onChange={handleAccordionChange("logos")}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="subtitle1">Organization Logos</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Box>
						<p className="text-sm md:text-base text-grey py-4">
							Enter your brand information for SpoofGuard to verify if your logo
							appears as an image on discovered websites.
						</p>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								gap: 2,
								mb: 4,
							}}
						>
							<Button
								variant="contained"
								startIcon={<CloudUploadIcon />}
								onClick={handleLogoUploadClick}
							>
								Upload Logo
							</Button>
						</Box>

						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Thumbnail</TableCell>
										<TableCell>Name</TableCell>
										<TableCell>Logo File Name</TableCell>
										<TableCell align="right">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{availableLogos.length > 0 ? (
										availableLogos.map((logo) => (
											<TableRow key={logo.id}>
												<TableCell>
													<Box
														sx={{
															width: 50,
															height: 50,
															bgcolor: "grey.100",
															borderRadius: 1,
														}}
													>
														<img
															src={logo.logo_link}
															alt={logo.name}
															style={{
																width: "100%",
																height: "100%",
																objectFit: "contain",
															}}
														/>
													</Box>
												</TableCell>
												<TableCell>{logo.name}</TableCell>
												<TableCell>
													{logo.logo_link
														? logo.logo_link.split("/").pop()
														: ""}
												</TableCell>
												<TableCell align="right">
													<IconButton
														size="small"
														onClick={() => handleDeleteLogo(logo.id)}
													>
														<DeleteIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell colSpan={4} align="center">
												No logos found
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</AccordionDetails>

				<Dialog
					open={uploadLogoOpen}
					onClose={() => setUploadLogoOpen(false)}
					maxWidth="sm"
					fullWidth
				>
					<DialogTitle>Upload Logo</DialogTitle>
					<DialogContent>
						<TextField
							fullWidth
							label="Logo Name"
							value={logoName}
							onChange={(e) => setLogoName(e.target.value)}
							sx={{ mb: 3, mt: 2 }}
						/>
						<Button
							variant="outlined"
							component="label"
							startIcon={<CloudUploadIcon />}
							className="mb-4"
						>
							Choose Logo File
							<input
								type="file"
								hidden
								accept="image/*"
								onChange={(e) => setLogoFile(e.target.files[0])}
							/>
						</Button>
						{logoFile && (
							<Typography variant="body2" className="mb-4">
								Selected file: {logoFile.name}
							</Typography>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setUploadLogoOpen(false)}>Cancel</Button>
						<Button
							variant="contained"
							onClick={() => {
								handleLogoSave();
								setUploadLogoOpen(false);
							}}
							disabled={!logoFile || !logoName || logoUploading}
						>
							Save Logo
						</Button>
					</DialogActions>
				</Dialog>
			</Accordion>

			<Accordion
				expanded={expandedPanel === "branding"}
				onChange={handleAccordionChange("branding")}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="subtitle1">Brand Keywords</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Box>
						<p className="text-sm md:text-base text-grey py-4">
							Enter your brand information for SpoofGuard to verify if your
							brand name appears as text on discovered websites.
						</p>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								gap: 2,
								mb: 4,
							}}
						>
							<Button
								variant="contained"
								startIcon={<AddIcon />}
								onClick={() => {
									setBrandingName("");
									setBrandingKeywords(["", "", ""]);
									setShowBrandingForm(true);
								}}
							>
								Add Branding Keywords
							</Button>
						</Box>

						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Keywords</TableCell>
										<TableCell align="right">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{availableBranding.length > 0 ? (
										availableBranding.map((brand) => (
											<TableRow key={brand.id}>
												<TableCell>{brand.name}</TableCell>
												<TableCell>{brand.keywords.join(", ")}</TableCell>
												<TableCell align="right">
													<IconButton
														size="small"
														onClick={() => handleEditBranding(brand)}
													>
														<EditIcon />
													</IconButton>
													<IconButton
														size="small"
														onClick={() => handleDeleteBranding(brand.id)}
													>
														<DeleteIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell colSpan={3} align="center">
												No brand keywords found
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>

						<Dialog
							open={showBrandingForm}
							onClose={() => {
								setShowBrandingForm(false);
								setEditingBranding(null);
								setBrandingName("");
								setBrandingKeywords(["", "", ""]);
							}}
							maxWidth="sm"
							fullWidth
						>
							<DialogTitle>
								{editingBranding ? "Edit Brand Keywords" : "Add Brand Keywords"}
							</DialogTitle>
							<DialogContent>
								<TextField
									fullWidth
									label="Brand Name"
									value={brandingName}
									onChange={(e) => setBrandingName(e.target.value)}
									sx={{ mb: 3, mt: 2 }}
								/>
								{[0, 1, 2].map((index) => (
									<TextField
										key={index}
										fullWidth
										label={`Keyword ${index + 1}`}
										value={brandingKeywords[index]}
										onChange={(e) => {
											const updated = [...brandingKeywords];
											updated[index] = e.target.value;
											setBrandingKeywords(updated);
										}}
										sx={{ mb: 2 }}
									/>
								))}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => {
										setShowBrandingForm(false);
										setEditingBranding(null);
										setBrandingName("");
										setBrandingKeywords(["", "", ""]);
									}}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									onClick={() => {
										handleBrandingSave();
										setShowBrandingForm(false);
										setEditingBranding(null);
									}}
									disabled={!brandingName || !brandingKeywords.some((k) => k)}
								>
									{editingBranding ? "Save Changes" : "Save Keywords"}
								</Button>
							</DialogActions>
						</Dialog>
						<Dialog
							open={editBrandingOpen}
							onClose={() => {
								setEditBrandingOpen(false);
								setEditingBranding(null);
								setBrandingName("");
								setBrandingKeywords(["", "", ""]);
							}}
							maxWidth="sm"
							fullWidth
						>
							<DialogTitle>Edit Brand Keywords</DialogTitle>
							<DialogContent>
								<TextField
									fullWidth
									label="Brand Name"
									value={brandingName}
									onChange={(e) => setBrandingName(e.target.value)}
									sx={{ mb: 3, mt: 2 }}
								/>
								{[0, 1, 2].map((index) => (
									<TextField
										key={index}
										fullWidth
										label={`Keyword ${index + 1}`}
										value={brandingKeywords[index]}
										onChange={(e) => {
											const updated = [...brandingKeywords];
											updated[index] = e.target.value;
											setBrandingKeywords(updated);
										}}
										sx={{ mb: 2 }}
									/>
								))}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => {
										setEditBrandingOpen(false);
										setEditingBranding(null);
										setBrandingName("");
										setBrandingKeywords(["", "", ""]);
									}}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									onClick={handleBrandingEditSave}
									disabled={!brandingName || !brandingKeywords.some((k) => k)}
								>
									Save Changes
								</Button>
							</DialogActions>
						</Dialog>
					</Box>
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expandedPanel === "poa"}
				onChange={handleAccordionChange("poa")}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="subtitle1">Power of Attorney Files</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Box>
						<p className="text-sm md:text-base text-grey py-4">
							Upload a signed Power of Attorney document that authorizes
							SpoofGuard to send takedown requests on behalf your organization.
						</p>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								gap: 2,
								mb: 4,
							}}
						>
							<Button
								variant="outlined"
								startIcon={<CloudDownloadIcon />}
								color="primary"
								sx={{
									cursor: "pointer",
									"&:hover": {
										textDecoration: "underline",
									},
									width: "fit-content",
									display: "flex",
									alignItems: "center",
								}}
								onClick={() => {
									getPowerFile()
										.then((response) => {
											const url = window.URL.createObjectURL(
												new Blob([response.data])
											);
											const link = document.createElement("a");
											link.href = url;
											link.setAttribute("download", "authorization_letter.odt");
											document.body.appendChild(link);
											link.click();

											enqueueSnackbar("Powerfile downloaded", {
												variant: "success",
											});
										})
										.catch(() => {
											enqueueSnackbar("Unable to download powerfile", {
												variant: "error",
											});
										});
								}}
							>
								Download Sample File
							</Button>
							<Button
								variant="contained"
								startIcon={<CloudUploadIcon />}
								onClick={() => setUploadPoaOpen(true)}
							>
								Upload New File
							</Button>
						</Box>

						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>File Name</TableCell>
										<TableCell align="right">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{availablePOAs.length > 0 ? (
										availablePOAs.map((poa) => (
											<TableRow key={poa.id}>
												<TableCell>{poa.name}</TableCell>
												<TableCell>
													{poa.signed_power_file_link
														? poa.signed_power_file_link.split("/").pop()
														: ""}
												</TableCell>
												<TableCell align="right">
													<IconButton
														size="small"
														onClick={() => handleDeletePOA(poa.id)}
													>
														<DeleteIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell colSpan={3} align="center">
												No Power of Attorney files found
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>

						<Dialog
							open={uploadPoaOpen}
							onClose={() => setUploadPoaOpen(false)}
							maxWidth="sm"
							fullWidth
						>
							<DialogTitle>Upload Power of Attorney</DialogTitle>
							<DialogContent>
								<TextField
									fullWidth
									label="Document Name"
									value={poaName}
									onChange={(e) => setPoaName(e.target.value)}
									sx={{ mb: 3, mt: 2 }}
								/>
								<Button
									variant="outlined"
									component="label"
									startIcon={<CloudUploadIcon />}
									className="mb-4"
								>
									Choose File
									<input
										type="file"
										hidden
										accept=".pdf,.doc,.docx,.odt"
										onChange={(e) => setPoaFile(e.target.files[0])}
									/>
								</Button>
								{poaFile && (
									<Typography variant="body2" className="mb-4">
										Selected file: {poaFile.name}
									</Typography>
								)}
							</DialogContent>
							<DialogActions>
								<Button onClick={() => setUploadPoaOpen(false)}>Cancel</Button>
								<Button
									variant="contained"
									onClick={() => {
										handlePOASave();
										setUploadPoaOpen(false);
									}}
									disabled={!poaFile || !poaName || poaUploading}
								>
									Upload
								</Button>
							</DialogActions>
						</Dialog>
					</Box>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default AssetManagement;
