"use client";
import { Cloud, Shield, UserCog, ArrowDown } from "lucide-react";
import "./widgets.css";

const processSteps = [
	{
		title: "REGISTRAR & HOSTING PROVIDER COMPLAINTS",
		description:
			"We generate and send official abuse reports to domain registrars and hosting providers. These reports include evidence such as screenshots comparing the legitimate and fraudulent sites.",
		Icon: Cloud,
	},
	{
		title: "BLACKLIST SUBMISSIONS",
		description:
			"We submit malicious domains to security blocklists like Google Safe Browsing and Microsoft Defender to prevent user access.",
		Icon: Shield,
		progressBars: 3,
	},
	{
		title: "POWER OF ATTORNEY TAKEDOWN SYSTEM",
		description:
			"Users can authorize SpoofGuard to handle domain takedown requests directly, enabling faster action against threats.",
		Icon: UserCog,
	},
];

function ProcessFlow() {
	return (
		<div className="process-container process-flow-css">
			<div className="process-flow">
				{processSteps.map((step, index) => (
					<div key={index} className="process-step">
						<div className="step-content shadow">
							<div className="step-icon">
								<step.Icon size={24} />
							</div>
							<div className="step-text">
								<h3>{step.title}</h3>
								<p>{step.description}</p>
							</div>
						</div>
						{index < processSteps.length - 1 && (
							<div className="flow-arrow">
								<div className="arrow-line"></div>
								<ArrowDown className="arrow-icon" size={24} />
								<div className="arrow-line"></div>
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
}

export default ProcessFlow;
