import React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { sendContact } from "../api";

//assets
//import why from "../assets/images/pages/technology/why.webp";
// template css
import "./../assets/styles/primary-template.css";
import "./../assets/scripts/technology-scripts.js";

//
import MethodsSlider from "./page-widgets/MethodsSlider";
import MonitoringFeatures from "./page-widgets/MonitoringFeatures";
import ProcessFlow from "./page-widgets/ProcessFlow";
import FeatureTabs from "./page-widgets/FeatureTabs";
import RiskAssessmentCards from "./page-widgets/RiskAssessmentCards.js";

const Technology = () => {
	// Contact form state
	const [contactState, setContactState] = useState({
		email: "",
		name: "",
		subject: "Spoofguard contact form submission",
		text: "",
	});

	const { enqueueSnackbar } = useSnackbar();

	// Handle input changes
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setContactState((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	// Handle form submission
	const submitContact = (e) => {
		e.preventDefault();

		const formData = new FormData();
		Object.keys(contactState).forEach((key) => {
			formData.append(key, contactState[key]);
		});

		sendContact(formData)
			.then((res) => {
				enqueueSnackbar("Contact form submitted.", {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar("Could not send the form.", {
					variant: "error",
				});
			});
	};
	return (
		<>
			<section className="hero-techBG security-question">
				<div className="clearfix section-breaks smaller-breaks"></div>
				<div className="container">
					<div className="row">
						<div className="hero-content-techBG col">
							<div className="divv">
								<p className="d-none"></p>
								<h3>
									The Technology Behind Our Domain and Brand Abuse Detection
									Engine
								</h3>
								<p>
									Cybercriminals use deceptive domain tactics to impersonate
									brands, trick users, and steal sensitive information. Phishing
									websites can be created using misspelled versions of
									legitimate domains (typosquatting) or hosted on unrelated
									servers. At SpoofGuard, we continuously scan the internet for
									threats targeting your brand and domain, including domain
									impersonation, phishing sites, and malware distribution. Our
									detection engine combines multiple methods to identify and
									mitigate these threats effectively.
								</p>
								<div className="buttons">
									<a
										href="https://calendly.com/spoofguard"
										className="demo-button"
										target="_blank"
										rel="noreferrer"
									>
										Book a Demo
									</a>
									<a
										href="/register"
										className="demo-button second-type-button"
										target="_blank"
									>
										Free Trial
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="clearfix section-breaks"></div>
			{/* First Section: Detection Methods */}
			<section
				className="technology-section-third second-of-third shadow"
				style={{
					background:
						"linear-gradient(102deg, #f8f9fa 25%, rgba(23, 143, 202, 0.4901960784) 160%)",
				}}
			>
				<div className="container">
					<div className="row">
						<div className="col-12 text-center mb-5">
							<h1
								className="text-left"
								style={{
									color: "#000000",
									fontSize: "40px",
								}}
							>
								How We <strong>Detect</strong> Potentially Malicious Domains
							</h1>
							<p className="text-left">
								Our comprehensive detection system leverages multiple data
								sources
							</p>
						</div>
					</div>
					<div>
						<FeatureTabs />
					</div>
				</div>
			</section>
			<section className="section-padding technology pb-0">
				<section
					className="technology-section-third"
					style={{ background: "#ffffff" }}
				>
					<div className="container">
						<div className="row pt-3 mt-2">
							<div className="col-12">
								<h1 className="mb-0 pb-0 mt-3 pt-3 text-left class-h1-40">
									How We <strong>Investigate</strong> Potentially Malicious Domains
								</h1>
								<p className="text-left">
									If we detect a suspicious domain, our system examines multiple
									data points to determine its risk level
								</p>
							</div>
						</div>
					</div>

					<div className="container-fluid">
						<section
							className="technology-section technology-section-third"
							style={{
								background: "#ffffff",
								maxWidth: "1400px",
								margin: "auto",
							}}
						>
							<MethodsSlider />
						</section>
						<section className="risk-assessment-css">
							<div className="container">
								<div className="row">
									<div className="col">
										<h1
											 className="pb-0 mt-3 pt-3 text-left class-h1-40 mb-4">
											How We <strong>Identify</strong> High-Risk Websites
										</h1>
									</div>
								</div>
								<RiskAssessmentCards />
							</div>
						</section>
					</div>
				</section>
				<section className="technology-section-third shadow">
					<div className="container">
						<div className="row pt-3 mt-2">
							<div>
								<section className="monitoring-section py-5">
									<div className="container">
										<div className="row">
											<div className="col-12 text-center mb-5">
												<h1 className="mb-3 text-left class-h1-40">
													How We <strong>Monitor</strong> Domain Changes and Create Alerts
												</h1>
												<p className="text-left">
													Real-time monitoring system with instant alerts
												</p>
											</div>
										</div>
										<div className="row align-items-stretch">
											<MonitoringFeatures />
										</div>
									</div>
								</section>
							</div>
						</div>
					</div>
				</section>
				<div className="clearfix section-breaks"></div>

				<section className="why-choose-section inner-why-choose" id="why">
					<div className="container" style={{ textAlign: "center" }}>
						<h1 className="text-left">How We <strong>Take Down</strong> Malicious Domains</h1>
						<p className="text-left">
							Once a fraudulent domain is confirmed, SpoofGuard automates the
							takedown process through:
						</p>
						<div style={{ position: "relative" }} className="process-flow-css">
							{/** 
							<ProcessFlow />
                            */}
							<div className="steps-container">
								<div className="step">
									<div className="step-content">
										<div class="step-icon">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="60"
												height="60"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
												class="lucide lucide-cloud"
											>
												<path d="M17.5 19H9a7 7 0 1 1 6.71-9h1.79a4.5 4.5 0 1 1 0 9Z"></path>
											</svg>
										</div>

										<div className="step-text">
											<h2>Registrar & Hosting Provider Complaints</h2>
											<p>
												We generate and send official abuse reports to domain
												registrars and hosting providers. These reports include
												evidence such as screenshots comparing the legitimate
												and fraudulent sites.
											</p>
										</div>
									</div>
								</div>
								<img
									src="/assets/images/online/curve1.png"
									alt="Curve Line"
									className="curve-line"
								/>

								<div className="step reverse">
									<div className="step-content">
										<div class="step-icon">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="60"
												height="60"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
												class="lucide lucide-shield"
											>
												<path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
											</svg>
										</div>

										<div className="step-text">
											<h2>Blacklist Submissions</h2>
											<p>
												We submit malicious domains to security blocklists like
												Google Safe Browsing and Microsoft Defender to prevent
												user access.
											</p>
										</div>
									</div>
								</div>

								<img
									src="/assets/images/online/curve2.png"
									alt="Curve Line"
									className="curve-line"
								/>

								<div className="step">
									<div className="step-content">
										<div class="step-icon">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="60"
												height="60"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
												class="lucide lucide-user-cog"
											>
												<circle cx="18" cy="15" r="3"></circle>
												<circle cx="9" cy="7" r="4"></circle>
												<path d="M10 15H6a4 4 0 0 0-4 4v2"></path>
												<path d="m21.7 16.4-.9-.3"></path>
												<path d="m15.2 13.9-.9-.3"></path>
												<path d="m16.6 18.7.3-.9"></path>
												<path d="m19.1 12.2.3-.9"></path>
												<path d="m19.6 18.7-.4-1"></path>
												<path d="m16.8 12.3-.4-1"></path>
												<path d="m14.3 16.6 1-.4"></path>
												<path d="m20.7 13.8 1-.4"></path>
											</svg>
										</div>

										<div className="step-text">
											<h2>Power of Attorney Takedown System</h2>
											<p>
												Users can authorize SpoofGuard to handle domain takedown
												requests directly, enabling faster action against
												threats.
											</p>
										</div>
									</div>
								</div>
								<img
									src="/assets/images/online/curve3.png"
									alt="Curve Line"
									className="curve-line"
								/>
								{/**
								<div className="step reverse">
									<div className="step-content">
										<img
											src="/assets/images/online/Group 12.png"
											alt="Step 4"
											className="step-img"
										/>
										<div className="step-text">
											<h2>4. Proactive Monitoring</h2>
											<p>
												Stay one step ahead with real-time alerts. Activate
												monitoring for suspicious domains, and we’ll notify you
												the moment new domains surface mimicking your brand,
												keywords, or logos. With SpoofGuard, you’re never caught
												off guard.
											</p>
										</div>
									</div>
								</div>
								<img
									src="/assets/images/online/curve4.png"
									alt="Curve Line"
									className="curve-line"
								/>

								<div className="step">
									<div className="step-content">
										<img
											src="/assets/images/online/step5.png"
											alt="Step 5"
											className="step-img"
										/>
										<div className="step-text">
											<h2>5. Swift Action</h2>
											<p>
												Take control with confidence. When a malicious domain is
												confirmed, SpoofGuard works seamlessly to file takedown
												requests with hosting and DNS providers. Submit
												offending sites to blacklists directly from our
												platform. Fast, effective, and effortless.
											</p>
										</div>
									</div>
								</div>
                                 */}
							</div>
						</div>
					</div>
				</section>

				{/**
				<div className="how">
					<div className="container">
						<div className="row">
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-justify">
								<div className="icon">
									<svg
										width="41"
										height="37"
										viewBox="0 0 41 37"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M2.82471 2.94922V12.9492H6.82471V4.94922H14.8247V0.949219H4.82471C4.29427 0.949219 3.78557 1.15993 3.41049 1.53501C3.03542 1.91008 2.82471 2.41879 2.82471 2.94922ZM38.8247 12.9492V2.94922C38.8247 2.41879 38.614 1.91008 38.2389 1.53501C37.8638 1.15993 37.3551 0.949219 36.8247 0.949219H26.8247V4.94922H34.8247V12.9492H38.8247ZM34.8247 32.9492H26.8247V36.9492H36.8247C37.3551 36.9492 37.8638 36.7385 38.2389 36.3634C38.614 35.9884 38.8247 35.4797 38.8247 34.9492V24.9492H34.8247V32.9492ZM14.8247 36.9492V32.9492H6.82471V24.9492H2.82471V34.9492C2.82471 35.4797 3.03542 35.9884 3.41049 36.3634C3.78557 36.7385 4.29427 36.9492 4.82471 36.9492H14.8247ZM0.824707 16.9492H40.8247V20.9492H0.824707V16.9492Z"
											fill="black"
										/>
									</svg>
								</div>
								<div className="content">
									<h2 className="text-left">1. Enter Your Domain</h2>
									<p>
										SpoofGuard analyzes your domain against thousands of
										typo-squatted variations, leveraging real-world insights to
										stay ahead of attackers. Our expert-curated permutations
										ensure you’re always protected by the latest intelligence.
									</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-justify">
								<div className="icon">
									<svg
										width="49"
										height="49"
										viewBox="0 0 49 49"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M20.1582 36.9492C23.7082 36.9485 27.1558 35.7601 29.9522 33.5732L38.7442 42.3652L41.5722 39.5372L32.7802 30.7452C34.9682 27.9485 36.1573 24.5001 36.1582 20.9492C36.1582 12.1272 28.9802 4.94922 20.1582 4.94922C11.3362 4.94922 4.1582 12.1272 4.1582 20.9492C4.1582 29.7712 11.3362 36.9492 20.1582 36.9492ZM20.1582 8.94922C26.7762 8.94922 32.1582 14.3312 32.1582 20.9492C32.1582 27.5672 26.7762 32.9492 20.1582 32.9492C13.5402 32.9492 8.1582 27.5672 8.1582 20.9492C8.1582 14.3312 13.5402 8.94922 20.1582 8.94922Z"
											fill="black"
										/>
										<path
											d="M22.9822 18.1214C23.7402 18.8814 24.1582 19.8854 24.1582 20.9494H28.1582C28.16 19.8982 27.9534 18.8572 27.5504 17.8864C27.1474 16.9155 26.5559 16.0342 25.8102 15.2934C22.7822 12.2694 17.5322 12.2694 14.5062 15.2934L17.3302 18.1254C18.8502 16.6094 21.4702 16.6134 22.9822 18.1214Z"
											fill="black"
										/>
									</svg>
								</div>
								<div className="content">
									<h2 className="text-left">2. Detect and Analyze</h2>
									<p>
										Our powerful detection engine identifies typo-squatted
										domains, verifying DNS records and isolating threats with
										pinpoint accuracy. View active threats in real time,
										complete with isolated screenshots of malicious sites.
									</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-justify">
								<div className="icon">
									<svg
										width="49"
										height="49"
										viewBox="0 0 49 49"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M44.4912 10.9492C44.4912 7.64122 41.7992 4.94922 38.4912 4.94922H10.4912C7.18321 4.94922 4.49121 7.64122 4.49121 10.9492V16.6112C4.49121 18.7172 5.25521 20.6312 6.49121 22.1032V38.9492C6.49121 41.1552 8.28521 42.9492 10.4912 42.9492H38.4912C40.6972 42.9492 42.4912 41.1552 42.4912 38.9492V22.1012C43.7272 20.6312 44.4912 18.7172 44.4912 16.6092V10.9492ZM40.4912 10.9492V16.6112C40.4912 18.8912 38.7932 20.8352 36.7092 20.9452L36.4912 20.9492C34.2852 20.9492 32.4912 19.1552 32.4912 16.9492V8.94922H38.4912C39.5952 8.94922 40.4912 9.84722 40.4912 10.9492ZM20.4912 8.94922H28.4912V16.9492C28.4912 19.1552 26.6972 20.9492 24.4912 20.9492C22.2852 20.9492 20.4912 19.1552 20.4912 16.9492V8.94922ZM8.49121 10.9492C8.49121 9.84722 9.38721 8.94922 10.4912 8.94922H16.4912V16.9492C16.4912 19.1552 14.6972 20.9492 12.4912 20.9492L12.2732 20.9432C10.1892 20.8352 8.49121 18.8912 8.49121 16.6112V10.9492ZM20.4912 38.9492V32.9492H28.4912V38.9492H20.4912ZM32.4912 38.9492V32.9492C32.4912 30.7432 30.6972 28.9492 28.4912 28.9492H20.4912C18.2852 28.9492 16.4912 30.7432 16.4912 32.9492V38.9492H10.4912V24.6872C10.9992 24.8212 11.5252 24.9092 12.0652 24.9372C13.271 25.0027 14.4759 24.7935 15.589 24.3254C16.7022 23.8574 17.6945 23.1427 18.4912 22.2352C19.9572 23.8992 22.1052 24.9492 24.4912 24.9492C26.8772 24.9492 29.0252 23.8992 30.4912 22.2352C31.2879 23.1427 32.2802 23.8574 33.3934 24.3254C34.5065 24.7935 35.7114 25.0027 36.9172 24.9372C37.4592 24.9092 37.9832 24.8212 38.4912 24.6872V38.9492H32.4912Z"
											fill="black"
										/>
									</svg>
								</div>
								<div className="content">
									<h2 className="text-left">3. Brand Context Awareness</h2>
									<p>
										Your brand is unique—our defense is too. Share your name and
										logo, and SpoofGuard will track unauthorized use across
										scanned sites. Whether it’s text, images, or subtle
										imitations, we’ve got your brand covered.
									</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-justify">
								<div className="icon">
									<svg
										width="49"
										height="49"
										viewBox="0 0 49 49"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M24.8247 4.94922C13.9827 4.94922 4.82471 14.1092 4.82471 24.9492C4.82471 35.7892 13.9827 44.9492 24.8247 44.9492C35.6667 44.9492 44.8247 35.7892 44.8247 24.9492C44.8247 14.1092 35.6667 4.94922 24.8247 4.94922ZM24.8247 40.9492C16.1507 40.9492 8.82471 33.6212 8.82471 24.9492C8.82471 16.9532 15.0547 10.1152 22.8247 9.09522V13.1292C17.1587 14.0872 12.8247 19.0152 12.8247 24.9492C12.8247 31.5672 18.2067 36.9492 24.8247 36.9492C28.0147 36.9492 31.0247 35.6972 33.2987 33.4232L30.4707 30.5932C29.7322 31.3398 28.8529 31.9324 27.8837 32.3368C26.9146 32.7412 25.8748 32.9494 24.8247 32.9492C20.4127 32.9492 16.8247 29.3612 16.8247 24.9492C16.8247 21.2332 19.3827 18.1272 22.8247 17.2332V21.5252C21.6447 22.2312 20.8247 23.5112 20.8247 24.9492C20.8247 27.1112 22.6627 28.9492 24.8247 28.9492C26.9867 28.9492 28.8247 27.1112 28.8247 24.9492C28.8247 23.5112 28.0047 22.2312 26.8247 21.5252V9.09522C34.5947 10.1152 40.8247 16.9532 40.8247 24.9492C40.8247 33.6212 33.4987 40.9492 24.8247 40.9492Z"
											fill="black"
										/>
									</svg>
								</div>
								<div className="content">
									<h2 className="text-left">4. Proactive Monitoring</h2>
									<p>
										Stay one step ahead with real-time alerts. Activate
										monitoring for suspicious domains, and we’ll notify you the
										moment new domains surface mimicking your brand, keywords,
										or logos. With SpoofGuard, you’re never caught off guard.
									</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-justify">
								<div className="icon">
									<svg
										width="37"
										height="41"
										viewBox="0 0 37 41"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M36.1481 10.7492C36.1149 10.4131 35.9969 10.0909 35.8052 9.81282C35.6135 9.53473 35.3544 9.30984 35.0521 9.15919L19.0521 1.15919C18.7742 1.02014 18.4678 0.947754 18.1571 0.947754C17.8464 0.947754 17.5399 1.02014 17.2621 1.15919L1.26209 9.15919C0.960635 9.3107 0.702314 9.53586 0.511071 9.81381C0.319828 10.0918 0.201855 10.4135 0.168088 10.7492C0.146088 10.9632 -1.75391 32.2832 17.3461 40.7772C17.6013 40.8924 17.8781 40.9519 18.1581 40.9519C18.4381 40.9519 18.7149 40.8924 18.9701 40.7772C38.0701 32.2832 36.1701 10.9652 36.1481 10.7492ZM18.1581 36.7432V20.9492H5.17809C4.40152 18.1058 4.03516 15.1662 4.09009 12.2192L18.1581 5.18519V20.9492H31.0781C29.5601 26.4292 26.0821 32.9072 18.1581 36.7432Z"
											fill="black"
										/>
									</svg>
								</div>
								<div className="content">
									<h2 className="text-left">5. Swift Action</h2>
									<p>
										Take control with confidence. When a malicious domain is
										confirmed, SpoofGuard works seamlessly to file takedown
										requests with hosting and DNS providers. Submit offending
										sites to blacklists directly from our platform. Fast,
										effective, and effortless.
									</p>
								</div>
							</div>
						</div>
					</div> 
				</div>*/}

				<div className="clearfix section-breaks mt-0 pt-0 m-0 p-0"></div>
				{/**
				<div className="faq">
					<div className="container">
						<div className="row">
							<div className="col-lg-4 col-sm-12">
								<h1>FAQs</h1>
								<p>
									Discover answers to your most pressing questions about our
									technology and its implementation.
								</p>
							</div>
							<div className="col-lg-8 col-sm-12">
								<div className="faq-section">
									<Accordion items={faqItems} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="clearfix section-breaks"></div>
                 */}
				<div className="request-part">
					<section className="hero d-flex">
						<div className="hero-content align-content-xl-center align-content-xs-center">
							<h1 className="text-center center-text">
								Request Your Free Demo Today
							</h1>
							<p>
								Discover how SpoofGuard can protect your online presence. Sign
								up for a demo now!
							</p>
							<form>
								<a
									className="demo-button"
									href="https://calendly.com/spoofguard"
									target="_blank"
									rel="noreferrer"
								>
									Book a Demo
								</a>
							</form>
						</div>
					</section>
				</div>
				{/*contact us section */}
				<div className="contact-us-section" id="contact">
					<div
						className="container-fluid"
						style={{
							display: "flex",
							padding: "0px",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<div className="contact-us-content row container">
							<div className="contact-us-right col-md-12 col-lg-12">
								<h1 className="contact-title" style={{ color: "#ffffff" }}>
									Contact Us
								</h1>
								<form className="contact-form" onSubmit={submitContact}>
									<div className="form-row">
										<div className="form-group">
											<i className="fa fa-user icon"></i>
											<input
												type="text"
												id="name"
												name="name"
												placeholder="First Name"
												className="input-icon"
												onChange={handleInputChange}
												required
											/>
										</div>
									</div>
									<div className="form-row">
										<div className="form-group">
											<i className="fa fa-envelope icon"></i>
											<input
												type="email"
												id="email"
												name="email"
												placeholder="Email"
												className="input-icon"
												onChange={handleInputChange}
												required
											/>
										</div>
									</div>
									<div className="form-row">
										<div className="form-group">
											<i
												className="fa fa-comments icon"
												style={{ marginTop: "-78px" }}
											></i>
											<textarea
												id="text"
												name="text"
												placeholder="Leave Message"
												className="input-icon"
												onChange={handleInputChange}
												required
											></textarea>
										</div>
									</div>
									<div className="contact-us-buttons">
										<button type="submit" className="submit-button">
											Send
										</button>
										<a
											href="https://calendly.com/spoofguard"
											target="_blank"
											className="demo-button"
											rel="noreferrer"
										>
											Book a Demo
										</a>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Technology;
