import React, { useEffect, useState } from 'react';
import {
    Container, Box, Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Paper,
    CardHeader,
    LinearProgress,
    Pagination,
} from '@mui/material';
import AlertsTable from '../../components/alerts-table';
import { getAllAlerts } from '../../api';
import DashboardLayout from '../../components/Dashboard/layout';
import { useLocation } from 'react-router-dom';

const Alerts = () => {
    const [alerts, setAlerts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pendingDelete, setPendingDelete] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const location = useLocation();
    const preselectFilter = location?.state?.filter;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);


    useEffect(() => {
        setLoading(true)

        const pageConfig = {
            page: currentPage,
            page_size: 20,
        }

        getAllAlerts(pageConfig).then((response) => {
            setAlerts(response.data?.results)
            setLoading(false)

            const pages = Math.ceil(response.data.count / 20);
            setTotalPages(pages)
        }).catch(() => {
            setLoading(false)
        })
    }, [currentPage])

    const handlePageChange = (_, newPage) => {
        setCurrentPage(newPage);
    };

    const handleDeleteModal = (monitor) => {
        setOpenDialog(true);
        setPendingDelete({
            monitor_id: monitor.uid,
            analysis_id: monitor.analysis_id
        })
    }

    const handleDeleteModalClose = () => {
        setOpenDialog(false);
        setPendingDelete(null)
    }

    const handleAlertDelete = (analysis_id) => {
        const data = {
            monitor_ids: [pendingDelete.monitor_id]
        }
    }

    return (
        <DashboardLayout>
            <Container maxWidth="100%" sx={{ p: 3 }}>
                <>
                    <Paper elevation={3} sx={{ p: 0, mb: 0 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                            <CardHeader title="Alerts" sx={{ p: 0 }} />
                        </Box>
                        <Box>
                            {loading &&
                                <LinearProgress />
                            }
                        </Box>

                        <AlertsTable alerts={alerts} onDeleteAlert={handleDeleteModal} preselectFilter={preselectFilter} />
                    </Paper>
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            variant="outlined"
                            shape="rounded"
                        />
                    </Box>
                </>
            </Container>


            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Delete Alert</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this alert? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteModalClose()}>Cancel</Button>
                    <Button onClick={() => handleAlertDelete()} color="error">
                        Delete Alert
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};
export default Alerts;
