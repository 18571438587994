import axios from "axios";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import useraccount from "../../../../assets/images/user_account_large.png";
import {
	deleteaccount,
	updateProfile,
} from "../../../../actions/accountActions";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import lock from "../../../../assets/images/icons/lock-svgrepo-com.png";
import unlock from "../../../../assets/images/icons/unlock-svgrepo-com.png";
import { changepassword } from "../../../../actions/accountActions";
// also new - for ui
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Checkbox,
	FormControlLabel,
	IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// end end new

const Profile = (props) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [isChecked, setIsChecked] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const handleOnChange = () => {
		setIsChecked(!isChecked);
	};

	const handleDelete = async () => {
		try {
			if (!isChecked) {
				const message = "Please confirm check box!";
				enqueueSnackbar(message, {
					variant: "error",
				});
			} else {
				await dispatch(deleteaccount());
				enqueueSnackbar("Account close success!", {
					variant: "success",
				});
				window.location.href = "/";
			}
		} catch (error) {
			const message = "Account close failed!";
			enqueueSnackbar(message, {
				variant: "error",
			});
		}
	};

	const [isOldPassword, setOldPassword] = useState(false);
	const [isNewPassword, setNewPassword] = useState(false);
	const [isConfirmPassword, setConfirmPassword] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const handleOldPassword = () => {
		setOldPassword(!isOldPassword);
	};

	const handleNewPassword = () => {
		setNewPassword(!isNewPassword);
	};

	const handleConfirmPassword = () => {
		setConfirmPassword(!isConfirmPassword);
	};

	const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
	const [profilePic, setProfilePic] = useState(useraccount);
	const [imageFile, setImageFile] = useState(null);
	const fileInputRef = useRef(null);

	// Update handleProfilePicUpload function

	const handleProfilePicUpload = async (event) => {
		const file = event.target.files[0];
		if (file) {
			if (!file.type.startsWith("image/")) {
				enqueueSnackbar("Please upload an image file", { variant: "error" });
				return;
			}

			if (file.size > 5 * 1024 * 1024) {
				enqueueSnackbar("Image size should be less than 5MB", {
					variant: "error",
				});
				return;
			}

			try {
				const formData = new FormData();

				const requiredFields = [
					"email",
					"phone_number",
					"salutation",
					"first_name",
					"last_name",
					"street",
					"postal_code",
					"city",
					"country",
					"organization",
				];

				requiredFields.forEach((field) => {
					formData.append(field, props.account[field] || "");
				});

				formData.append("profile_picture", file);

				await dispatch(updateProfile(formData));

				const userDetailsResponse = await axios.get(
					`${process.env.REACT_APP_BASIC_API_URL}/user-details/`
				);

				setProfilePic(
					userDetailsResponse.data.profile_picture_link || useraccount
				);

				enqueueSnackbar("Profile picture updated successfully", {
					variant: "success",
				});
			} catch (error) {
				console.error("Upload error:", error);
				enqueueSnackbar("Failed to update profile picture", {
					variant: "error",
				});
				setProfilePic(props.account?.profile_picture_link || useraccount);
			}
		}
	};

	return (
		<div className="flex flex-col gap-6">
			<div className="rounded-lg shadow-md bg-white p-6 hover:shadow-lg transition-shadow">
				<div className="flex flex-col md:flex-row gap-6">
					<div className="flex flex-col items-center md:items-start">
						<div className="relative">
							<img
								src={props.account?.profile_picture_link || useraccount}
								alt="Profile"
								className="w-[120px] h-[120px] rounded-full mb-4 object-cover"
							/>
							<button
								onClick={() => fileInputRef.current?.click()}
								className="absolute bottom-1 right-1 p-2 rounded-full bg-white shadow-lg hover:bg-gray-50 transition-colors"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
									className="text-gray-600"
								>
									<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
									<path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
								</svg>
							</button>
							<input
								type="file"
								hidden
								ref={fileInputRef}
								accept="image/*"
								onChange={handleProfilePicUpload}
							/>
						</div>
					</div>
					<Dialog
						open={uploadDialogOpen}
						onClose={() => setUploadDialogOpen(false)}
						maxWidth="sm"
						fullWidth
					>
						<DialogTitle>Update Profile Picture</DialogTitle>
						<DialogContent>
							<Box sx={{ p: 2 }}>
								<Button
									variant="outlined"
									fullWidth
									component="label"
									startIcon={<CloudUploadIcon />}
								>
									Choose Image
									<input
										type="file"
										hidden
										accept="image/*"
										onChange={handleProfilePicUpload}
									/>
								</Button>
								<Typography
									variant="caption"
									display="block"
									sx={{ mt: 1, textAlign: "center" }}
								>
									Supported formats: JPG, PNG (max 5MB)
								</Typography>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setUploadDialogOpen(false)}>Cancel</Button>
							<Button
								variant="contained"
								onClick={async () => {
									if (imageFile) {
										try {
											const formData = new FormData();
											formData.append("profile_picture", imageFile);
											enqueueSnackbar("Profile picture updated successfully", {
												variant: "success",
											});
											setUploadDialogOpen(false);
										} catch (error) {
											enqueueSnackbar("Failed to update profile picture", {
												variant: "error",
											});
										}
									}
								}}
							>
								Save
							</Button>
						</DialogActions>
					</Dialog>
					<div className="flex-1">
						<Formik
							enableReinitialize={true}
							className="flex "
							initialValues={{
								first_name: props.account.first_name,
								last_name: props.account.last_name,
								email: props.account.email,
								organization: props.account.organization,
								phone_number: props.account.phone_number,
								country: props.account.country,
								salutation: props.account.salutation,
								street: props.account.street,
								postal_code: props.account.postal_code,
								city: props.account.city,
								organization_url: props.account.organization_url,
							}}
							validationSchema={Yup.object().shape({
								first_name: Yup.string()
									.required("First name is required")
									.matches(
										/^[a-zA-Z\s]*$/,
										"First name should only contain letters"
									),
								last_name: Yup.string()
									.required("Last name is required")
									.matches(
										/^[a-zA-Z\s]*$/,
										"Last name should only contain letters"
									),
								email: Yup.string()
									.email("Must be a valid email")
									.required("Email is required"),
								organization: Yup.string()
									.required("Organization is required")
									.min(2, "Organization name must be at least 2 characters"),
							})}
							onSubmit={async (
								values,
								{ setErrors, setStatus, setSubmitting }
							) => {
								try {
									// Include all required fields in the request body
									const request_body = {
										email: values.email,
										phone_number: values.phone_number || "",
										salutation: values.salutation || "",
										first_name: values.first_name,
										last_name: values.last_name,
										street: values.street || "",
										postal_code: values.postal_code || "",
										city: values.city || "",
										country: values.country || "",
										organization: values.organization,
										organization_url: values.organization_url || "",
									};

									// Use the updateProfile action
									await dispatch(updateProfile(request_body));

									// Show success message
									enqueueSnackbar("Profile updated successfully!", {
										variant: "success",
									});

									// Disable editing mode after successful update
									setIsEditing(false);
									setSubmitting(false);

									// Force form to show updated values
									//values = {
									//	...values,
									//	...request_body,
									//};
								} catch (error) {
									console.error("Update error:", error);
									enqueueSnackbar("Update failed!", {
										variant: "error",
									});
									setStatus({ success: false });
									setErrors({ submit: error.message });
									setSubmitting(false);
								}
							}}
						>
							{(
								//{
								//errors,
								//handleBlur,
								//handleChange,
								//handleSubmit,
								//isSubmitting,
								//touched,
								//values,
								{
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
								}
							) => (
								<form
									//onSubmit={handleSubmit}
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit(e);
									}}
									className="grid md:grid-cols-2 gap-4"
								>
									<div>
										<label className="block text-sm text-gray-600 mb-1">
											First Name <span className="text-[#EB5757]">*</span>
										</label>
										<input
											type="text"
											name="first_name"
											value={values.first_name}
											onChange={handleChange}
											onBlur={handleBlur}
											disabled={!isEditing}
											className={`w-full h-10 px-4 border rounded-lg transition-colors ${
												!isEditing
													? "bg-gray-50 text-gray-500 cursor-not-allowed"
													: touched.first_name && errors.first_name
													? "border-red-500 focus:ring-2 focus:ring-red-500"
													: "focus:ring-2 focus:ring-primary focus:border-transparent"
											}`}
										/>
										{touched.first_name && errors.first_name && isEditing && (
											<div className="text-red-500 text-xs mt-1">
												*{errors.first_name}
											</div>
										)}
									</div>
									<div>
										<label className="block text-sm text-gray-600 mb-1">
											Last Name <span className="text-[#EB5757]">*</span>
										</label>
										<input
											type="text"
											name="last_name"
											value={values.last_name}
											onChange={handleChange}
											onBlur={handleBlur}
											disabled={!isEditing}
											className={`w-full h-10 px-4 border rounded-lg transition-colors ${
												!isEditing
													? "bg-gray-50 text-gray-500 cursor-not-allowed"
													: touched.last_name && errors.last_name
													? "border-red-500 focus:ring-2 focus:ring-red-500"
													: "focus:ring-2 focus:ring-primary focus:border-transparent"
											}`}
										/>
										{touched.last_name && errors.last_name && isEditing && (
											<div className="text-red-500 text-xs mt-1">
												*{errors.last_name}
											</div>
										)}
									</div>
									<div>
										<label className="block text-sm text-gray-600 mb-1">
											Email <span className="text-[#EB5757]">*</span>
										</label>
										<input
											name="email"
											type="email"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.email}
											placeholder="Email"
											required
											disabled={!isEditing}
											className={`w-full h-10 px-4 border rounded-lg transition-colors ${
												!isEditing
													? "bg-gray-50 text-gray-500 cursor-not-allowed"
													: touched.email && errors.email
													? "border-red-500 focus:ring-2 focus:ring-red-500"
													: "focus:ring-2 focus:ring-primary focus:border-transparent"
											}`}
										/>
										{touched.email && errors.email && isEditing && (
											<div className="text-red-500 text-xs mt-1">
												*{errors.email}
											</div>
										)}
									</div>
									<div>
										<label className="block text-sm text-gray-600 mb-1">
											Organization <span className="text-[#EB5757]">*</span>
										</label>
										<input
											name="organization"
											type="organization"
											// onBlur={handleBlur}
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.organization}
											placeholder="Organization"
											disabled={!isEditing}
											className={`w-full h-10 px-4 border rounded-lg transition-colors ${
												!isEditing
													? "bg-gray-50 text-gray-500 cursor-not-allowed"
													: touched.organization && errors.organization
													? "border-red-500 focus:ring-2 focus:ring-red-500"
													: "focus:ring-2 focus:ring-primary focus:border-transparent"
											}`}
										/>
										{touched.organization &&
											errors.organization &&
											isEditing && (
												<div className="text-red-500 text-xs mt-1">
													*{errors.organization}
												</div>
											)}
									</div>
									<div className="md:col-span-2 flex justify-end mt-4">
										<button
											type="button"
											onClick={() => setIsEditing(!isEditing)}
											className="p-2 hover:bg-gray-100 rounded-full transition-colors"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
												className={`${
													isEditing ? "text-primary" : "text-gray-400"
												}`}
											>
												<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
												<path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
											</svg>
										</button>
										{/**
										<button
											type="submit"
											disabled={!isEditing || isSubmitting}
											className={`px-6 py-2 rounded-lg transition-colors ${
												isEditing
													? "bg-primary text-white hover:bg-primary/90"
													: "bg-gray-100 text-gray-400 cursor-not-allowed"
											}`}
										>
											{isSubmitting ? "Saving..." : "Save Changes"}
                                        </button>
                                         */}

										<button
											type="submit"
											disabled={
												!isEditing ||
												isSubmitting ||
												Object.keys(errors).length > 0
											}
											className={`px-6 py-2 rounded-lg transition-colors ${
												isEditing && !Object.keys(errors).length
													? "bg-primary text-white hover:bg-primary/90"
													: "bg-gray-100 text-gray-400 cursor-not-allowed"
											}`}
										>
											{isSubmitting ? "Saving..." : "Save Changes"}
										</button>
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</div>

			<div className="rounded-lg shadow-md bg-white hover:shadow-lg transition-shadow">
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						className="px-6 py-3"
					>
						<Typography className="font-medium">
							Security - Change Password
						</Typography>
					</AccordionSummary>
					<AccordionDetails className="px-6 pb-6">
						<Formik
							initialValues={{
								old_password: "",
								new_password1: "",
								new_password2: "",
							}}
							/*
							validationSchema={Yup.object().shape({
								old_password: Yup.string()
									.min(7)
									.matches(
										/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
										"Password must contain at least one uppercase letter, one lowercase letter, and one number"
									)
									.max(255)
									.required("Password is required"),
								new_password1: Yup.string()
									.min(7)
									.matches(
										/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
										"Password must contain at least one uppercase letter, one lowercase letter, and one number"
									)
									.max(255)
									.required("New Password is required"),
								new_password2: Yup.string()
									.min(7)
									.matches(
										/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
										"Password must contain at least one uppercase letter, one lowercase letter, and one number"
									)
									.max(255)
									.required("Confirm Password is required"),
							})}
                                    */
							validationSchema={Yup.object().shape({
								old_password: Yup.string()
									.min(7, "Password must be at least 7 characters")
									.matches(
										/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
										"Password must contain at least one uppercase letter, one lowercase letter, and one number"
									)
									.required("Current password is required"),
								new_password1: Yup.string()
									.min(7, "Password must be at least 7 characters")
									.matches(
										/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
										"Password must contain at least one uppercase letter, one lowercase letter, and one number"
									)
									.notOneOf(
										[Yup.ref("old_password")],
										"New password must be different from old password"
									)
									.required("New password is required"),
								new_password2: Yup.string()
									.oneOf([Yup.ref("new_password1")], "Passwords must match")
									.required("Please confirm your new password"),
							})}
							/*
							onSubmit={async (
								values,
								{ setErrors, setStatus, setSubmitting }
							) => {
								try {
									if (values.new_password2 !== values.new_password1) {
										enqueueSnackbar("Not matched Password!", {
											variant: "error",
										});
										return;
									}

									if (values.new_password1 === values.old_password) {
										enqueueSnackbar(
											"New password must be different from old password",
											{
												variant: "error",
											}
										);
										return;
									}

									await dispatch(changepassword(values));
									enqueueSnackbar("Password Change Success!", {
										variant: "success",
									});
								} catch (error) {
									const message = "Old Password is Wrong!";
									enqueueSnackbar(message, {
										variant: "error",
									});
									setStatus({ success: false });
									setErrors({ submit: message });
									setSubmitting(false);
								}
							}}
                        */
							onSubmit={async (
								values,
								{ setErrors, setStatus, setSubmitting }
							) => {
								try {
									// Check passwords match
									if (values.new_password1 !== values.new_password2) {
										enqueueSnackbar("Passwords do not match", {
											variant: "error",
										});
										return;
									}

									// Check new password is different from old
									if (values.new_password1 === values.old_password) {
										enqueueSnackbar(
											"New password must be different from old password",
											{
												variant: "error",
											}
										);
										return;
									}

									// Try to update password
									await dispatch(
										changepassword({
											old_password: values.old_password,
											new_password1: values.new_password1,
											new_password2: values.new_password2,
										})
									);

									enqueueSnackbar("Password updated successfully!", {
										variant: "success",
									});

									// Reset form
									values.old_password = "";
									values.new_password1 = "";
									values.new_password2 = "";
								} catch (error) {
									const message =
										error.response?.data?.detail || "Old password is incorrect";
									enqueueSnackbar(message, { variant: "error" });
									setStatus({ success: false });
									setErrors({ old_password: message });
								} finally {
									setSubmitting(false);
								}
							}}
						>
							{({
								errors,
								handleBlur,
								handleChange,
								handleSubmit,
								isSubmitting,
								touched,
								values,
							}) => (
								<form onSubmit={handleSubmit} className="grid gap-4">
									<div>
										<div className="relative">
											<input
												className={`w-full h-10 px-4 border rounded-lg pr-10 ${
													touched.old_password && errors.old_password
														? "border-red-500 focus:ring-2 focus:ring-red-500"
														: "focus:ring-2 focus:ring-primary focus:border-transparent"
												}`}
												name="old_password"
												type={!isOldPassword ? "password" : "text"}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.old_password}
												placeholder="Old Password"
												required
											/>
											{touched.old_password && errors.old_password && (
												<div className="text-red-500 text-xs mt-1">
													*{errors.old_password}
												</div>
											)}
											<button
												type="button"
												onClick={handleOldPassword}
												className="absolute right-3 top-1/2 -translate-y-1/2"
											>
												<img
													src={isOldPassword ? unlock : lock}
													alt="toggle password"
													className="w-5 h-5"
												/>
											</button>
										</div>
									</div>
									<div>
										<div className="relative">
											<input
												className={`w-full h-10 px-4 border rounded-lg pr-10 ${
													touched.new_password1 && errors.new_password1
														? "border-red-500 focus:ring-2 focus:ring-red-500"
														: "focus:ring-2 focus:ring-primary focus:border-transparent"
												}`}
												name="new_password1"
												type={!isNewPassword ? "password" : "text"}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.new_password1}
												placeholder="New Password"
												required
											/>
											{touched.new_password1 && errors.new_password1 && (
												<div className="text-red-500 text-xs mt-1">
													*{errors.new_password1}
												</div>
											)}
											<button
												type="button"
												onClick={handleNewPassword}
												className="absolute right-3 top-1/2 -translate-y-1/2"
											>
												<img
													src={isNewPassword ? unlock : lock}
													onClick={handleNewPassword}
													alt="toggle password"
													className="w-5 h-5"
												/>
											</button>
										</div>
									</div>
									<div>
										<div className="relative">
											<input
												className={`w-full h-10 px-4 border rounded-lg pr-10 ${
													touched.new_password2 && errors.new_password2
														? "border-red-500 focus:ring-2 focus:ring-red-500"
														: "focus:ring-2 focus:ring-primary focus:border-transparent"
												}`}
												name="new_password2"
												type={!isConfirmPassword ? "password" : "text"}
												// onBlur={handleBlur}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.new_password2}
												placeholder="Confirm Password"
												required
											/>
											{touched.new_password2 && errors.new_password2 && (
												<div className="text-red-500 text-xs mt-1">
													*{errors.new_password2}
												</div>
											)}
											<button
												type="button"
												onClick={handleConfirmPassword}
												className="absolute right-3 top-1/2 -translate-y-1/2"
											>
												<img
													src={isConfirmPassword ? unlock : lock}
													onClick={handleConfirmPassword}
													alt="toggle password"
													className="w-5 h-5"
												/>
											</button>
										</div>
									</div>
									<div className="flex justify-end mt-4">
										{/** 
                                        <button
											type="submit"
											className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors"
										>
											Update Password
										</button>
                                        */}
										<button
											type="submit"
											disabled={isSubmitting || Object.keys(errors).length > 0}
											className={`px-6 py-2 rounded-lg transition-colors ${
												!Object.keys(errors).length
													? "bg-primary text-white hover:bg-primary/90"
													: "bg-gray-100 text-gray-400 cursor-not-allowed"
											}`}
										>
											{isSubmitting ? "Updating..." : "Update Password"}
										</button>
									</div>
								</form>
							)}
						</Formik>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className="rounded-lg shadow-md bg-white p-6 hover:shadow-lg transition-shadow">
				<p className="font-bold text-[#EB5757]">Delete your account</p>
				<p className="text-sm md:text-base text-grey py-4">
					Deleting your account is permanent and cannot be undone. All your data
					will be permanently erased
				</p>
				<div className="flex justify-end gap-2">
					<button
						onClick={() => setDeleteDialogOpen(true)}
						className="px-6 py-2 bg-[#EB5757] text-white rounded-lg hover:bg-[#EB5757]/90 transition-colors"
					>
						Delete Account
					</button>
				</div>
			</div>

			<Dialog
				open={deleteDialogOpen}
				onClose={() => {
					setDeleteDialogOpen(false);
					setIsChecked(false);
				}}
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						Delete Account?
						<IconButton
							size="small"
							onClick={() => {
								setDeleteDialogOpen(false);
								setIsChecked(false);
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Typography sx={{ mb: 3 }}>
						When you delete your account, your access to account services is
						revoked, and your personal data is permanently erased. You have 14
						days to cancel the deletion if you change your mind.
					</Typography>
					<FormControlLabel
						control={
							<Checkbox
								checked={isChecked}
								onChange={handleOnChange}
								className="accent-primary"
							/>
						}
						label="Confirm that I want to delete my account"
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setDeleteDialogOpen(false);
							setIsChecked(false);
						}}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={handleDelete}
						disabled={!isChecked}
					>
						Delete Account
					</Button>
				</DialogActions>
			</Dialog>

			<style jsx>{`
				@media (max-width: 768px) {
					.md\\:flex-row {
						flex-direction: column;
					}
					.md\\:grid-cols-2 {
						grid-template-columns: 1fr;
					}
					.md\\:items-start {
						align-items: center;
					}
				}
			`}</style>
		</div>
	);
};

export default Profile;
