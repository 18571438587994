"use client";

import { useState } from "react";
import "./widgets.css";

const methods_inv = [
	{
		title: "DNS Records and WHOIS Data",
		content:
			"We analyze domain ownership, registration details, and hosting infrastructure.",
	},
	{
		title: "Website Content Extraction and AI Analysis",
		content:
			"Our AI engines scan text, images, and branding elements to detect unauthorized use of logos, trademarks, and brand-related content.",
	},
	{
		title: "Custom Website Risk Rating",
		content:
			"Our risk assessment system evaluates multiple technical and behavioral indicators to determine a domain's legitimacy.",
	},
];

function MethodsSlider() {
	const [currentSlide, setCurrentSlide] = useState(0);

	const nextSlide = () => {
		setCurrentSlide((current) =>
			current === methods_inv.length - 1 ? 0 : current + 1
		);
	};

	const prevSlide = () => {
		setCurrentSlide((current) =>
			current === 0 ? methods_inv.length - 1 : current - 1
		);
	};

	const goToSlide = (index) => {
		setCurrentSlide(index);
	};

	return (
		<div className="container methods-slide-css">
			<div className="slider-wrapper">
				<div
					className="slider-content"
					style={{ transform: `translateX(-${currentSlide * 100}%)` }}
				>
					{methods_inv.map((method, index) => (
						<div key={index} className="slide">
							<div className="slide-inner">
								<span className="step-indicator">Step {index + 1}</span>
								<h3>{method.title}</h3>
								<p className="text-center">{method.content}</p>
							</div>
						</div>
					))}
				</div>

				<button className="nav-button prev" onClick={prevSlide}>
					&#8592;
				</button>
				<button className="nav-button next" onClick={nextSlide}>
					&#8594;
				</button>

				<div className="dots-container">
					{methods_inv.map((_, index) => (
						<button
							key={index}
							className={`dot ${currentSlide === index ? "active" : ""}`}
							onClick={() => goToSlide(index)}
						/>
					))}
				</div>
			</div>
		</div>
	);
}

export default MethodsSlider;
