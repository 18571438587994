import moment from "moment";
import { FileDownload } from '@mui/icons-material';
import { useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import './../../assets/styles/common-use-css.css';
const ReportsTable = ({
  analysisResult = [],
  currentPage,
  selectedItems,
  selectAll,
  handleSelectAll,
  handleCheckboxChange,
  handleExportCSV,
  handleDownloadCSVSecond,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  
  const handleRowSelect = (id) => {
    const newSelected = selectedRows.includes(id) 
      ? selectedRows.filter(rowId => rowId !== id)
      : [...selectedRows, id];
    setSelectedRows(newSelected);
  };

  const handleSelectAllRows = (event) => {
    if (event.target.checked && analysisResult) {
      setSelectedRows(analysisResult.map(item => item.uid));
    } else {
      setSelectedRows([]);
    }
  };

  const handleBulkDownloadVariations = async () => {
    try {
      if (selectedRows.length === 1) {
        const item = analysisResult.find(x => x.uid === selectedRows[0]);
        return handleExportCSV(item.uid);
      }

      const selectedItems = analysisResult.filter(item => 
        selectedRows.includes(item.uid)
      );

      const zip = new JSZip();
      
      for (const item of selectedItems) {
        const csvData = await generateCSVData(item); 
        zip.file(`variations_${item.uid}.csv`, csvData);
      }

      const zipContent = await zip.generateAsync({type: "blob"});
      saveAs(zipContent, "variations.zip");
      
    } catch (error) {
      console.error("Error downloading variations:", error);
      enqueueSnackbar("Failed to download variations", { variant: "error" });
    }
  };

  const handleBulkDownloadAnalysis = async () => {
    try {
      if (selectedRows.length === 1) {
        const item = analysisResult.find(x => x.uid === selectedRows[0]);
        return handleDownloadCSVSecond(item.uid);
      }

      const selectedItems = analysisResult.filter(item => 
        selectedRows.includes(item.uid)
      );

      const zip = new JSZip();
      
      for (const item of selectedItems) {
        const analysisData = await generateAnalysisData(item); 
        zip.file(`analysis_${item.uid}.csv`, analysisData);
      }

      const zipContent = await zip.generateAsync({type: "blob"});
      saveAs(zipContent, "analysis_results.zip");

    } catch (error) {
      console.error("Error downloading analysis:", error);
      enqueueSnackbar("Failed to download analysis results", { variant: "error" });
    }
  };

  return (
      <>
               {selectedRows.length > 0 && (
        <div className="mt-4 flex gap-4 justify-end">
          <button
            onClick={handleBulkDownloadVariations}
            className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-ikwmjk-MuiButtonBase-root-MuiButton-root common-style-third"
          >
            <FileDownload className="w-5 h-5 mr-2" />
            {selectedRows.length === 1 ? 'Download Variations' : 'Download Selected Variations'}
          </button>
          <button
            onClick={handleBulkDownloadAnalysis}
            className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-ikwmjk-MuiButtonBase-root-MuiButton-root common-style-third"
          >
            <FileDownload className="w-5 h-5 mr-2" />
            {selectedRows.length === 1 ? 'Download Analysis Results' : 'Download Selected Analysis'}  
          </button>
        </div>
      )}
      <div className="pt-4 md:pt-8 bg-white rounded-xl w-full min-h-[600px] overflow-x-auto">
        <table className="w-full min-w-[800px] border-collapse">
          <thead>
            <tr className="border-b">
              <th className="w-[50px] py-4 px-6">
                <input
                  type="checkbox"
                  checked={analysisResult && selectedRows.length === analysisResult.length}
                  onChange={handleSelectAllRows}
                  className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
              </th>
              <th className="common-style-first py-4 px-6 text-left text-xs font-medium tracking-wider">Domain</th>
              <th className="common-style-first py-4 px-6 text-left text-xs font-medium tracking-wider">Date</th>
              <th className="common-style-first py-4 px-6 text-left text-xs font-medium tracking-wider">Type</th>
              <th className="common-style-first py-4 px-6 text-left text-xs font-medium tracking-wider">Records</th>
              <th className="common-style-first py-4 px-6 text-center text-xs font-medium tracking-wider">Domain Variations</th>
              <th className="common-style-first py-4 px-6 text-center text-xs font-medium tracking-wider">Analysis Results</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {analysisResult && Array.isArray(analysisResult) && analysisResult.length > 0 ? (
              analysisResult
                .slice((currentPage - 1) * 10, currentPage * 10)
                .map((item, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 custom-css-hover-gray transition-colors duration-200"
                  >
                    <td className="py-4 px-6">
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item.uid)}
                        onChange={() => handleRowSelect(item.uid)}
                        className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                    </td>
                    <td className="py-4 px-6 text-sm common-style-second">{item.domain}</td>
                    <td className="py-4 px-6 text-sm common-style-second">
                      {moment(item.created_at).format("MMM DD, YYYY")}
                    </td>
                    <td className="py-4 px-6 text-sm common-style-second">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                        CSV
                      </span>
                    </td>
                    <td className="py-4 px-6 text-sm common-style-second">{item.total_initial_done}</td>
                    <td className="py-4 px-6">
                      <div className="flex justify-center">
                        <button
                          onClick={() => handleExportCSV(item.uid)}
                          className="text-gray-400 hover:text-blue-600 transition-colors duration-200"
                          title="Download Domain Variations"
                        >
                          <FileDownload className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex justify-center">
                        <button
                          onClick={() => handleDownloadCSVSecond(item.uid)}
                          className="text-gray-400 hover:text-blue-600 transition-colors duration-200"
                          title="Download Analysis Results"
                        >
                          <FileDownload className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="7" className="py-8 text-center text-gray-500">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
{/**
      {selectedRows.length > 0 && (
        <div className="mt-4 flex gap-4 justify-end">
          <button
            onClick={handleBulkDownloadVariations}
            className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-ikwmjk-MuiButtonBase-root-MuiButton-root common-style-third"
          >
            <FileDownload className="w-5 h-5 mr-2" />
            {selectedRows.length === 1 ? 'Download Variations' : 'Download Selected Variations'}
          </button>
          <button
            onClick={handleBulkDownloadAnalysis}
            className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-ikwmjk-MuiButtonBase-root-MuiButton-root common-style-third"
          >
            <FileDownload className="w-5 h-5 mr-2" />
            {selectedRows.length === 1 ? 'Download Analysis Results' : 'Download Selected Analysis'}  
          </button>
        </div>
          )}
           */}
    </>
  );
};

// Helper functions to generate CSV data
const generateCSVData = async (item) => {
  // Implement CSV generation for variations
  // Return CSV string
};

const generateAnalysisData = async (item) => {
  // Implement CSV generation for analysis results
  // Return CSV string
};

export default ReportsTable;
