import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	isMenuOpen: window.innerWidth <= 1440,
	analysis_id: "",
	config: {},
	module_settings: [],
	monitor_settings: [],
	loading: false,
	error: null,
	selected_domains: [],
	scan_domain: "",
	scanBrands: [],
	brand: [],
	brandLogo: "",
	authLetter: "",

	// 1st - newer used beolow Jan '25 Additions of new Scan Modal Feature
	// New state needed
	availableLogos: [],
	selectedLogoId: null,
	selectedLogo: null, // Add this new state property
	availableBranding: [],
	selectedBrandingId: null,
	availablePOA: [],
	selectedPOAId: null,
	poaFile: null,
};

// Async thunk for fetching config
export const fetchConfig = createAsyncThunk(
	"scan/fetchConfig",
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_BASIC_API_URL}/analysis/configs/`
			);
			return response.data; // The data to populate the `config` state
		} catch (error) {
			return rejectWithValue(error.response?.data || error.message);
		}
	}
);

const scanSlice = createSlice({
	name: "scan",
	initialState,
	reducers: {
		setIsMenuOpen(state, action) {
			state.isMenuOpen = action.payload;
		},
		updateAnalysisId(state, action) {
			state.analysis_id = action.payload;
		},
		updateScanDomain(state, action) {
			state.scan_domain = action.payload;
		},
		updateConfig(state, action) {
			state.config = action.payload;
		},
		updateModuleSettings(state, action) {
			state.module_settings = action.payload;
		},
		updateMonitorSettings(state, action) {
			state.monitor_settings = action.payload;
		},
		updateSelectedDomains(state, action) {
			const newDomains = action.payload.filter(
				(domain) => !state.selected_domains.includes(domain)
			);
			state.selected_domains = [...state.selected_domains, ...newDomains];
		},
		addBrand(state, action) {
			state.scanBrands = [
				...state.scanBrands, // Preserve the existing data
				{
					analysisId: action.payload.analysisId,
					brandName: action.payload.brandName,
					brandLogo: action.payload.brandLogo,
				},
			];
		},
		updateBrandLogo(state, action) {
			state.brandLogo = action.payload;
		},
		updateAuthLetter(state, action) {
			state.authLetter = action.payload;
		},
		resetBrand(state, action) {
			state.brand = null;
			state.brandLogo = null;
		},
		updateAvailableLogos(state, action) {
			state.availableLogos = action.payload;
		},
		setSelectedLogo(state, action) {
			state.selectedLogoId = action.payload;
			state.selectedLogo = state.availableLogos.find(
				(logo) => logo.id === action.payload
			);
		},
		updatePOAFile: (state, action) => {
			state.poaFile = action.payload;
		},
		updateSelectedPOA: (state, action) => {
			state.selectedPOAId = action.payload;
		},
		clearPOAData: (state) => {
			state.poaFile = null;
			state.selectedPOAId = null;
		},
	},
	extraReducers: (builder) => {
		// Handle fetchConfig lifecycle
		builder
			.addCase(fetchConfig.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchConfig.fulfilled, (state, action) => {
				state.loading = false;
				state.config = action.payload; // Populate config state
			})
			.addCase(fetchConfig.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const {
	setIsMenuOpen,
	updateAnalysisId,
	updateScanDomain,
	updateConfig,
	updateModuleSettings,
	updateMonitorSettings,
	updateSelectedDomains,
	updateBrand,
	updateBrandLogo,
	updateAuthLetter,
	resetBrand,
	addBrand,
	updateAvailableLogos,
	setSelectedLogo,
	updatePOAFile,
	updateSelectedPOA,
	clearPOAData,
} = scanSlice.actions;

export default scanSlice.reducer;
