import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Switch,
    Button,
    Collapse,
    IconButton,
    TextField,
    FormControlLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSelector } from 'react-redux';
import { getFileFromLocalStorage } from '../../utiles/general';
import { getSingleMonitor, getSingleMonitorByAnalysisId, startSetupMonitor, updateMonitor, updateAnalysisSettings } from '../../api';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

// MonitoringSection Component
const MonitoringSection = ({
    title,
    description,
    isExpanded,
    onToggle,
    tooltipText,
    children,
    isAllSelected,
    onSelectAll,
}) => (
    <Box
        sx={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            '&:last-child': { borderBottom: 'none' },
        }}
    >
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
                cursor: 'pointer',
                '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.02)' },
            }}
            onClick={onToggle}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                    size="small"
                    sx={{ mr: 1, color: 'text.secondary' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        onToggle();
                    }}
                >
                    {isExpanded ? <RemoveIcon /> : <AddIcon />}
                </IconButton>
                <Typography variant="body1" sx={{ mr: 1 }}>
                    {title}
                </Typography>
                {tooltipText && (
                    <Tooltip title={tooltipText}>
                        <HelpOutlineIcon sx={{ color: 'grey', fontSize: 20 }} />
                    </Tooltip>
                )}
            </Box>
            <FormControlLabel
                control={
                    <Switch
                        checked={isAllSelected}
                        onChange={(e) => {
                            e.stopPropagation();
                            onSelectAll(e.target.checked);
                        }}
                        size="small"
                    />
                }
                label="Select All"
                sx={{ mr: 0 }}
            />
        </Box>
        <Collapse in={isExpanded}>
            <Box sx={{ p: 2, bgcolor: 'rgba(0, 0, 0, 0.02)' }}>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {description}
                </Typography>
                {children}
            </Box>
        </Collapse>
    </Box>
);

// ToggleOption Component
const ToggleOption = ({ label, checked, onChange, tooltipText, disabled, disabledTooltip = "" }) => {
    return (
        <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2">{label}</Typography>
                {tooltipText && (
                    <Tooltip title={tooltipText}>
                        <HelpOutlineIcon sx={{ color: 'grey', fontSize: 16, ml: 1 }} />
                    </Tooltip>
                )}
            </Box>

            {!disabled ? (
                <Switch disabled={disabled} checked={checked && !disabled} onChange={onChange} size="small" />
            ) : (
                <Tooltip title={disabledTooltip}>
                    <span>
                        <Switch disabled={disabled} checked={checked && !disabled} onChange={onChange} size="small" />
                    </span>
                </Tooltip>
            )}
        </Box>
    )
};

export default function SimilarDomainMonitorModal({
    open,
    onClose,
    selectedDomains,
    onMonitorsEnabled,
    analysis,
}) {
    const [monitorSettings, setMonitorSettings] = useState({
        ns_record_appears_monitor: false,
        new_similar_domain_monitor: false
    });
    const initialSettingsRef = useRef(null);
    const { analysis_id } = useParams();

    // Load current settings when modal opens
    useEffect(() => {
        if (open && analysis) {
            const settings = {
                ns_record_appears_monitor: analysis.ns_record_appears_monitor || false,
                new_similar_domain_monitor: analysis.new_similar_domain_monitor || false
            };
            setMonitorSettings(settings);
            initialSettingsRef.current = settings;
        }
    }, [open, analysis]);

    // Check if settings have changed
    const hasChanged = useMemo(() => {
        if (!initialSettingsRef.current) return false;
        return Object.keys(monitorSettings).some(
            (key) => monitorSettings[key] !== initialSettingsRef.current[key]
        );
    }, [monitorSettings]);

    // Handle individual setting changes
    const handleSettingChange = (setting) => (event) => {
        setMonitorSettings(prev => ({
            ...prev,
            [setting]: event.target.checked
        }));
    };

    const enableMonitors = async () => {
        try {
            onMonitorsEnabled(true);
            await updateAnalysisSettings(analysis_id, monitorSettings);
            
            enqueueSnackbar('Monitor settings updated successfully', {
                variant: 'success',
            });
            onClose();
        } catch (error) {
            enqueueSnackbar('Failed to update monitor settings', {
                variant: 'error',
            });
        } finally {
            onMonitorsEnabled(false);
        }
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="monitor-modal-title">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    maxHeight: '90vh',
                    overflow: 'auto',
                }}
            >
                <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                        <Typography variant="h5" component="h2" sx={{ mr: 1 }}>
                           Live Monitoring
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            bgcolor: '#f8f9fa',
                            borderRadius: 1,
                            overflow: 'hidden',
                            border: '1px solid rgba(0, 0, 0, 0.1)',
                            mb: 3,
                        }}
                    >
                        <Box p={3} sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <ToggleOption
                                label="NS Record Appears"
                                checked={monitorSettings.ns_record_appears_monitor}
                                onChange={handleSettingChange('ns_record_appears_monitor')}
                                tooltipText="Monitor for new NS record appearances"
                            />
                            <ToggleOption
                                label="New Similar Domain Found"
                                checked={monitorSettings.new_similar_domain_monitor}
                                onChange={handleSettingChange('new_similar_domain_monitor')}
                                tooltipText="Monitor for new similar domains"
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Button
                            variant="contained"
                            disabled={!hasChanged}
                            onClick={enableMonitors}
                            sx={{
                                bgcolor: '#1976d2',
                                color: 'white',
                                '&:hover': { bgcolor: '#1565c0' },
                                textTransform: 'none',
                                px: 4,
                            }}
                        >
                            Update Settings
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}
