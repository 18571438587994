import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Switch,
    Button,
    Collapse,
    IconButton,
    TextField,
    FormControlLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSelector } from 'react-redux';
import { getFileFromLocalStorage } from '../../utiles/general';
import { startSetupMonitor, updateMonitor } from '../../api';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

// MonitoringSection Component
const MonitoringSection = ({
    title,
    description,
    isExpanded,
    onToggle,
    tooltipText,
    children,
    isAllSelected,
    onSelectAll,
}) => (
    <Box
        sx={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            '&:last-child': { borderBottom: 'none' },
        }}
    >
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
                cursor: 'pointer',
                '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.02)' },
            }}
            onClick={onToggle}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                    size="small"
                    sx={{ mr: 1, color: 'text.secondary' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        onToggle();
                    }}
                >
                    {isExpanded ? <RemoveIcon /> : <AddIcon />}
                </IconButton>
                <Typography variant="body1" sx={{ mr: 1 }}>
                    {title}
                </Typography>

            </Box>
            <FormControlLabel
                control={
                    <Switch
                        checked={isAllSelected}
                        onChange={(e) => {
                            e.stopPropagation();
                            onSelectAll(e.target.checked);
                        }}
                        size="small"
                    />
                }
                label="Select All"
                sx={{ mr: 0 }}
            />
        </Box>
        <Collapse in={isExpanded}>
            <Box sx={{ p: 2, bgcolor: 'rgba(0, 0, 0, 0.02)' }}>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {description}
                </Typography>
                {children}
            </Box>
        </Collapse>
    </Box>
);

// ToggleOption Component
const ToggleOption = ({ label, checked, onChange, tooltipText, disabled, disabledTooltip = "" }) => {
    return (
        <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2">{label}</Typography>
                {tooltipText && (
                    <Tooltip title={tooltipText}>
                        <HelpOutlineIcon sx={{ color: 'grey', fontSize: 16, ml: 1 }} />
                    </Tooltip>
                )}
            </Box>

            {!disabled ? (
                <Switch disabled={disabled} checked={checked && !disabled} onChange={onChange} size="small" />
            ) : (
                <Tooltip title={disabledTooltip}>
                    <span>
                        <Switch disabled={disabled} checked={checked && !disabled} onChange={onChange} size="small" />
                    </span>
                </Tooltip>
            )}
        </Box>
    )
};

export default function MonitorModal({
    open,
    onClose,
    selectedDomains,
    onMonitorsEnabled,
    analysis,
    monitor = null,
}) {
    const [expandedSections, setExpandedSections] = useState({});
    const { analysis_id: paramAnalysisId } = useParams();
    const effectiveAnalysisId = paramAnalysisId || monitor?.analysis_id;
    const initialConditionsRef = useRef(null);

    const configConditions = useSelector(state => state?.scan?.config?.alert_conditions);

    const [conditions, setConditions] = useState({
        content_change_and_spoof: false,
        a_record_change: false,
        mx_record_change: false,
        whois_change: false,
        port_change: false,
        phishing_status: false,
        urlscore_risk_change: false,
        content_change: false,
        is_spoofing: false,
    });

    const [brandInfo, setBrandInfo] = useState({
        brandName: '',
        logo: null,
    });

    useEffect(() => {
        if (monitor && monitor.conditions) {
            const newConditions = {
                content_change_and_spoof: monitor.conditions.includes('content_change_and_spoof'),
                a_record_change: monitor.conditions.includes('a_record_change'),
                mx_record_change: monitor.conditions.includes('mx_record_change'),
                whois_change: monitor.conditions.includes('whois_change'),
                port_change: monitor.conditions.includes('port_change'),
                phishing_status: monitor.conditions.includes('phishing_status'),
                urlscore_risk_change: monitor.conditions.includes('urlscore_risk_change'),
                content_change: monitor.conditions.includes('content_change'),
                is_spoofing: monitor.conditions.includes('is_spoofing'),
            };
            setConditions(newConditions);
            initialConditionsRef.current = newConditions;
        }
    }, [monitor]);

    // Compute if any condition has changed compared to the initial snapshot
    const hasChanged = useMemo(() => {
        if (!initialConditionsRef.current) return false;
        return Object.keys(conditions).some(
            (key) => conditions[key] !== initialConditionsRef.current[key]
        );
    }, [conditions]);

    // Toggle expanded sections
    const toggleSection = (section) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    // Update a single condition
    const handleConditionChange = (condition) => (event) => {
        setConditions((prev) => ({
            ...prev,
            [condition]: event.target.checked,
        }));
    };

    // Handle "Select All" for a section
    const handleSelectAll = (sectionId, isSelected) => {
        const sectionConditions = {
            contentChangedAndSpoofed: ['content_change_and_spoof', 'content_change', 'is_spoofing'],
            dnsRecords: ['a_record_change', 'mx_record_change', 'whois_change'],
            infrastructureMonitoring: ['port_change', 'phishing_status', 'urlscore_risk_change'],
        };

        setConditions((prev) => {
            const newConditions = { ...prev };
            sectionConditions[sectionId].forEach((condition) => {
                newConditions[condition] = isSelected;
            });
            return newConditions;
        });
    };

    // Check if all toggles in a section are selected
    const isAllSelected = (sectionCode) => {
        const sectionConditions = {
            contentChangedAndSpoofed: ['content_change_and_spoof', 'content_change', 'is_spoofing'],
            dnsRecords: ['a_record_change', 'mx_record_change', 'whois_change',],
            infrastructureMonitoring: ['port_change', 'phishing_status', 'urlscore_risk_change'],
        };

        return sectionConditions[sectionCode].every((condition) => conditions[condition]);
    };

    const handleBrandNameChange = (event) => {
        setBrandInfo((prev) => ({
            ...prev,
            brandName: event.target.value,
        }));
    };

    const handleLogoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setBrandInfo((prev) => ({
                ...prev,
                logo: file,
            }));
        }
    };

    const onCloseHandler = () => {
        setConditions(prevConditions => {
            const resetConditions = {};
            Object.keys(prevConditions).forEach(key => {
                resetConditions[key] = false;
            });
            return resetConditions;
        });

        onClose()
    }

    const monitoringSections = [
        {
            id: 'contentChangedAndSpoofed',
            title: 'Website Content and Brand Protection',
            description: 'Track website content changes and detect unauthorized use of your brand or logo',
            tooltipText: 'Detects if website content has been modified and is impersonating the original content',
        },
        {
            id: 'dnsRecords',
            title: 'DNS and Domain Monitoring',
            description: 'Monitor changes in A, MX and Whois records of the domain.',
            tooltipText: 'Tracks changes in domain name system records',
        },
        {
            id: 'infrastructureMonitoring',
            title: 'Infrastructure and Network Monitoring',
            description: 'Monitor for new open ports and appearances in phishing databases.',
            tooltipText: 'Tracks infrastructure changes and potential security threats',
        },
    ];

    const enableMonitors = async () => {
        if (monitor) {
            // For updating existing monitor
            const enabledConditions = Object.entries(conditions)
                .filter(([_, enabled]) => enabled)
                .map(([key]) => key);

            const data = {
                monitor_ids: [monitor.uid],
                conditions: enabledConditions
            };

            onMonitorsEnabled(true);
            
            updateMonitor(effectiveAnalysisId, data)
                .then((response) => {
                    onMonitorsEnabled(false);
                    onCloseHandler();
                    enqueueSnackbar('Monitor updated successfully', {
                        variant: 'success',
                    });
                })
                .catch((error) => {
                    onMonitorsEnabled(false);
                    enqueueSnackbar('Failed to update monitor', {
                        variant: 'error',
                    });
                });
        } else {
            // For creating new monitors - keep existing FormData logic
            const formData = new FormData();
            if (analysis.branding?.id) {
                formData.append('brand_id', analysis.branding?.id);
            }

            if (analysis.logo?.id) {
                formData.append('logo_id', analysis.logo?.id);
            }

            formData.append('auto_track_new_domains', false);
            formData.append('enabled', true);

            selectedDomains.forEach((domain) => {
                formData.append('domains', domain);
            });

            const conditionsArray = Object.keys(conditions)
                .filter((key) => conditions[key])
                .map((key) => key);

            conditionsArray.forEach((condition) => {
                formData.append('conditions', condition);
            });

            onMonitorsEnabled(true);

            startSetupMonitor(effectiveAnalysisId, formData).then((response) => {
                console.log(response);
                onMonitorsEnabled(false);
                enqueueSnackbar('Your monitors have been enabled successfully', {
                    variant: 'success',
                });
            }).catch((error) => {
                onMonitorsEnabled(false);
                if (
                    error?.response?.status === 400 &&
                    error?.response?.data?.details ===
                    'The following domains are already being monitored.'
                ) {
                    enqueueSnackbar('The following domains are already being monitored.', {
                        variant: 'error',
                    });
                } else {
                    enqueueSnackbar('There was an error when trying to enable the domain monitors', {
                        variant: 'error',
                    });
                }
            });
        }
    };

    return (
        <Modal open={open} onClose={onCloseHandler} aria-labelledby="monitor-modal-title">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    maxHeight: '90vh',
                    overflow: 'auto',
                }}
            >
                <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                        <Typography variant="h5" component="h2" sx={{ mr: 1 }}>
                            Monitor Static Domains
                        </Typography>
                        {/**
                        <Tooltip title="Set up monitoring conditions for selected domains">
                            <InfoIcon fontSize="small" sx={{ color: '#1976d2' }} />
                        </Tooltip>
                         */}
                    </Box>

                    <Box
                        sx={{
                            bgcolor: '#f8f9fa',
                            borderRadius: 1,
                            overflow: 'hidden',
                            border: '1px solid rgba(0, 0, 0, 0.1)',
                            mb: 3,
                        }}
                    >
                        {monitoringSections.map((section) => (
                            <MonitoringSection
                                key={section.id}
                                title={section.title}
                                description={section.description}
                                isExpanded={expandedSections[section.id]}
                                onToggle={() => toggleSection(section.id)}
                                tooltipText={section.tooltipText}
                                isAllSelected={isAllSelected(section.id)}
                                onSelectAll={(isSelected) => handleSelectAll(section.id, isSelected)}
                            >
                                {section.id === 'contentChangedAndSpoofed' && (
                                    <Box>

                                        <ToggleOption
                                            label={configConditions?.filter(x => x.code === "is_spoofing")[0].name}
                                            checked={conditions.is_spoofing}
                                            onChange={handleConditionChange('is_spoofing')}
                                            tooltipText={configConditions?.filter(x => x.code === "is_spoofing")[0].description}
                                            disabled={!analysis?.logo && !analysis?.branding}
                                            disabledTooltip={!analysis?.logo && !analysis?.branding ? monitor ? "No branding keywords or logos were uploaded in the scan. Select 'View Scan' to add your company branding under 'Detect Spoofing,' then enable this monitor for content spoof detection." : "No branding keywords or logos were uploaded in the scan. Select 'Detect Spoofing,' then enable this monitor for content spoof detection." : ""}
                                        />
                                        <ToggleOption
                                            label={configConditions?.filter(x => x.code === "content_change")[0].name}
                                            checked={conditions.content_change}
                                            onChange={handleConditionChange('content_change')}
                                            tooltipText={configConditions?.filter(x => x.code === "content_change")[0].description}
                                        />
                                        <ToggleOption
                                            label={configConditions?.filter(x => x.code === "content_change_and_spoof")[0].name}
                                            checked={conditions.content_change_and_spoof}
                                            onChange={handleConditionChange('content_change_and_spoof')}
                                            tooltipText={configConditions?.filter(x => x.code === "content_change_and_spoof")[0].description}
                                            disabled={!analysis?.logo && !analysis?.branding}
                                            disabledTooltip={!analysis?.logo && !analysis?.branding ? monitor ? "No branding keywords or logos were uploaded in the scan. Select 'View Scan' to add your company branding under 'Detect Spoofing,' then enable this monitor for content spoof detection." : "No branding keywords or logos were uploaded in the scan. Select 'Detect Spoofing,' then enable this monitor for content spoof detection." : ""}
                                        />

                                        {/* {conditions.content_change_and_spoof && !analysis.logo && !analysis.branding && (
                                            <Box sx={{ mt: 2 }}>
                                                <TextField
                                                    fullWidth
                                                    label="Brand Name"
                                                    variant="outlined"
                                                    value={brandInfo.brandName}
                                                    onChange={handleBrandNameChange}
                                                    sx={{ mb: 2 }}
                                                />
                                                <Button
                                                    component="label"
                                                    variant="outlined"
                                                    startIcon={<CloudUploadIcon />}
                                                    fullWidth
                                                    sx={{
                                                        color: '#1976d2',
                                                        borderColor: '#1976d2',
                                                        '&:hover': {
                                                            borderColor: '#1565c0',
                                                            bgcolor: 'rgba(25, 118, 210, 0.04)',
                                                        },
                                                    }}
                                                >
                                                    Upload Logo
                                                    <input type="file" hidden accept="image/*" onChange={handleLogoUpload} />
                                                </Button>
                                                {brandInfo.logo && (
                                                    <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                                                        Selected file: {brandInfo.logo.name}
                                                    </Typography>
                                                )}
                                            </Box>
                                        )} */}
                                    </Box>
                                )}
                                {section.id === 'dnsRecords' && (
                                    <Box>
                                        <ToggleOption
                                            label={configConditions?.filter(x => x.code === "a_record_change")[0].name}
                                            checked={conditions.a_record_change}
                                            onChange={handleConditionChange('a_record_change')}
                                            tooltipText={configConditions?.filter(x => x.code === "a_record_change")[0].description}
                                        />
                                        <ToggleOption
                                            label={configConditions?.filter(x => x.code === "mx_record_change")[0].name}
                                            checked={conditions.mx_record_change}
                                            onChange={handleConditionChange('mx_record_change')}
                                            tooltipText={configConditions?.filter(x => x.code === "mx_record_change")[0].description}
                                        />
                                        <ToggleOption
                                            label={configConditions?.filter(x => x.code === "whois_change")[0].name}
                                            checked={conditions.whois_change}
                                            onChange={handleConditionChange('whois_change')}
                                            tooltipText={configConditions?.filter(x => x.code === "whois_change")[0].description}
                                        />
                                    </Box>
                                )}
                                {section.id === 'infrastructureMonitoring' && (
                                    <Box>
                                        <ToggleOption
                                            label={configConditions?.filter(x => x.code === "port_change")[0].name}
                                            checked={conditions.port_change}
                                            onChange={handleConditionChange('port_change')}
                                            tooltipText={configConditions?.filter(x => x.code === "port_change")[0].description}
                                        />
                                        <ToggleOption
                                            label={configConditions?.filter(x => x.code === "phishing_status")[0].name}
                                            checked={conditions.phishing_status}
                                            onChange={handleConditionChange('phishing_status')}
                                            tooltipText={configConditions?.filter(x => x.code === "phishing_status")[0].description}
                                        />
                                        <ToggleOption
                                            label={configConditions?.filter(x => x.code === "urlscore_risk_change")[0].name}
                                            checked={conditions.urlscore_risk_change}
                                            onChange={handleConditionChange('urlscore_risk_change')}
                                            tooltipText={configConditions?.filter(x => x.code === "urlscore_risk_change")[0].description}
                                        />
                                    </Box>
                                )}
                            </MonitoringSection>
                        ))}
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Button
                            variant="contained"
                            disabled={!monitor ? false : !hasChanged}
                            onClick={enableMonitors}
                            sx={{
                                bgcolor: '#1976d2',
                                color: 'white',
                                '&:hover': { bgcolor: '#1565c0' },
                                textTransform: 'none',
                                px: 4,
                            }}
                        >
                            {monitor ? 'Update Monitor' : 'Enable Monitors'}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}
