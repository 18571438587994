"use client";
import "./widgets.css";

const monitoringFeatures = [
	{
		title: "Website Content Changes and Risk Analysis",
		description:
			"Detects unauthorized modifications, such as fraudulent brand name or logo usage, detects phishing attempts and flags changes in a domain’s risk level.",
		icon: "🔍", // Using emoji as placeholder, you can replace with actual icon
	},
	{
		title: "DNS Record Monitoring",
		description:
			"Tracks changes to critical records (NS, A, and MX) that may indicate domain hijacking.",
		icon: "🌐",
	},
	{
		title: "WHOIS & Domain Registrations",
		description: "Identifies newly registered domains that mimic your brand.",
		icon: "📝",
	},
	{
		title: "Infrastructure Monitoring",
		description:
			"Alerts on unauthorized port activity (e.g., HTTP/HTTPS) and phishing database listings.",
		icon: "🔐",
	},
];

function MonitoringFeatures() {
	return (
		<div className="monitoring-container monitoring-css container">
			<div className="monitoring-grid">
				{monitoringFeatures.map((feature, index) => (
					<div key={index} className="feature-card">
						<div className="feature-icon">{feature.icon}</div>
						<h3 className="feature-title">{feature.title}</h3>
						<p className="feature-description">{feature.description}</p>
					</div>
				))}
			</div>
		</div>
	);
}

export default MonitoringFeatures;
