import { useState } from 'react';
import {
    Paper,
    Typography,
    Box,
    IconButton,
    Modal,
    Card,
    CardMedia
} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

function ScreenshotCard({ screenshotUrl }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Placeholder image URL - replace with actual screenshot URL
    const imageUrl = screenshotUrl || 'https://via.placeholder.com/800x600';

    return (
        <>
            <Paper
                elevation={1}
                sx={{
                    p: 2,
                    mt: 2,
                    backgroundColor: '#ffffff',
                    position: 'relative'
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <Typography variant="h6" gutterBottom>
                        Screenshot
                    </Typography>
                    <IconButton
                        size="small"
                        onClick={() => setIsModalOpen(true)}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                        <OpenInFullIcon />
                    </IconButton>
                </Box>
                <Card sx={{
                    width: '100%',
                    height: 300,
                    overflow: 'auto',
                    position: 'relative'
                }}>
                    <CardMedia
                        component="img"
                        image={imageUrl}
                        alt="Malicious Website Screenshot"
                        sx={{
                            width: '100%',
                            minHeight: '100%',
                            objectFit: 'contain'
                        }}
                    />
                </Card>
            </Paper>

            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Card sx={{
                    maxWidth: '90vw',
                    maxHeight: '90vh',
                    outline: 'none',
                    overflow: 'hidden'
                }}>
                    <CardMedia
                        component="img"
                        image={imageUrl}
                        alt="Malicious Website Screenshot"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain'
                        }}
                    />
                </Card>
            </Modal>
        </>
    );
}

export default ScreenshotCard; 