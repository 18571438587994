import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import PhishingIcon from '@mui/icons-material/Phishing';
import LinkIcon from '@mui/icons-material/Link';
import PolicyIcon from '@mui/icons-material/Policy';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import HttpsIcon from '@mui/icons-material/Https';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import "./widgets.css";


const riskAssessmentData = [
  {
    icon: <VpnLockIcon sx={{ fontSize: 40 }} />,
    title: 'Domain Age Analysis',
    description: 'Domain age and trustworthiness'
  },
  {
    icon: <SubdirectoryArrowLeftIcon sx={{ fontSize: 40 }} />,
    title: 'Domain Structure Analysis',
    description: 'Use of dashes, excessive subdomains, or multiple dots in domain names'
  },
  {
    icon: <AssuredWorkloadIcon sx={{ fontSize: 40 }} />,
    title: 'Infrastructure Assessment',
    description: 'Hosting provider reputation and infrastructure risks'
  },
  {
    icon: <PhishingIcon sx={{ fontSize: 40 }} />,
    title: 'Blacklist Verification',
    description: 'Presence in known malware or phishing blacklists'
  },
  {
    icon: <LinkIcon sx={{ fontSize: 40 }} />,
    title: 'URL Analysis',
    description: 'Suspicious keywords, URL structures, or redirect behaviors'
  },
  {
    icon: <PolicyIcon sx={{ fontSize: 40 }} />,
    title: 'Obfuscation Detection',
    description: 'Detection of obfuscation techniques such as URL shorteners, HTTPS token manipulations, and unusual IP-based domains'
  },
  {
    icon: <BrowserUpdatedIcon sx={{ fontSize: 40 }} />,
    title: 'Web Risk Assessment',
    description: 'Web-based risks, including auto-download triggers, iframe injections, disabled right-clicking, and popup behavior'
  },
  {
    icon: <HttpsIcon sx={{ fontSize: 40 }} />,
    title: 'SSL & Domain History',
    description: 'SSL certificate integrity, WHOIS visibility, and domain registration history'
  },
  {
    icon: <AdminPanelSettingsIcon sx={{ fontSize: 40 }} />,
    title: 'Interaction Analysis',
    description: 'User interaction elements, such as webform structures, external resource dependencies, and abnormal redirects'
  },
  {
    icon: <BrandingWatermarkIcon sx={{ fontSize: 40 }} />,
    title: 'Content Analysis',
    description: 'Content-based heuristics that analyze website text, metadata, and branding elements for spoofing attempts'
  }
];

const RiskAssessmentCards = () => {
  return (
    <Grid container spacing={3}>
      {riskAssessmentData.map((item, index) => (
        <Grid item xs={12} md={6} key={index}>
          <Card 
            sx={{ 
              height: '100%',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
              }
            }}
          >
            <CardContent>
              <Box 
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 2,
                  color: '#1976d2'
                }}
              >
                {item.icon}
                <Box>
                  <Typography 
                    variant="h6" 
                    gutterBottom 
                    sx={{ 
                      fontWeight: 600,
                      color: '#2c3e50'
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography 
                    variant="body1"
                    sx={{ 
                      color: '#596777',
                      lineHeight: 1.6
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default RiskAssessmentCards;
