import React from "react";
import user_account from "../../assets/images/user_account.png";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/accountActions";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";

const TopLayout = (props) => {
	const navigate = useNavigate();
	const account = useSelector((state) => state.account);

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const handleLogout = async () => {
		try {
			dispatch(logout());
			localStorage.clear();

			navigate("/");
		} catch (error) {
			enqueueSnackbar("Unable to logout", {
				variant: "error",
			});
		}
	};

	return (
		<div>
			<div className="flex items-center justify-between">
				<div>
					<p className="text-base md:text-2xl font-semibold">
						Welcome &nbsp;
						{!account.user ? null : <span>{account.user.first_name}</span>}
					</p>
					{/* <p className="text-base md:text-2xl font-semibold">Welcome {account.user.first_name} {account.user.last_name}</p> */}
					<p className="text-sm md:text-base">{props.title}</p>
				</div>
				<Menu
					menuButton={
						<MenuButton>
							{/**
							<div className="flex items-center gap-3">
								<img src={user_account} alt="" />
								<p className="text-sm md:text-base hidden md:block">
									{!account.user ? null : (
										<span>
											{account.user.first_name} {account.user.last_name}
										</span>
									)}
								</p>
							</div>
                             */}

							<div className="flex items-center gap-3">
								<img
									src={account.user?.profile_picture_link || user_account}
									alt=""
									className="rounded-full object-cover"
									style={{ MaxHeight: "40px", maxWidth: "40px" }}
								/>
								<p
									className="text-sm md:text-base hidden md:block"
									style={{ display: "flex", margin: "0" }}
								>
									{!account.user ? null : (
										<span>
											{account.user.first_name} {account.user.last_name}
										</span>
									)}
								</p>
							</div>
						</MenuButton>
					}
					transition
				>
					<MenuItem>
						<a href="/">Back To Home</a>
					</MenuItem>
					<MenuItem onClick={handleLogout}>Logout</MenuItem>
					<MenuItem>
						<Link to={`/dashboard/settings`}>Settings</Link>
					</MenuItem>
				</Menu>
			</div>
			<div className="border-t-[0.5px] my-6"></div>
		</div>
	);
};

export default TopLayout;
