// accordion faq js
document.querySelectorAll(".technology .accordion-title").forEach((item) => {
	item.addEventListener("click", () => {
		const content = item.nextElementSibling;
		const arrow = item.querySelector(".arrow");
		if (content.style.display === "block") {
			content.style.display = "none";
			arrow.style.transform = "rotate(0deg)";
		} else {
			document
				.querySelectorAll(".accordion-content")
				.forEach((content) => (content.style.display = "none"));
			document
				.querySelectorAll(".arrow")
				.forEach((arrow) => (arrow.style.transform = "rotate(0deg)"));
			content.style.display = "block";
			arrow.style.transform = "rotate(180deg)";
		}
	});
});
