import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import accountReducer from "./accountReducer";
import scanReducer from "../slices/scan-slice";

const scanPersistConfig = {
	key: "scan",
	storage,
	//whitelist: ["scanBrands", 'authLetter'],
	// 1st - newer used beolow Jan '25 Additions of new Scan Modal Feature
	whitelist: [
		"scanBrands",
		"authLetter",
		"selectedLogoId",
		"selectedBrandingId",
		"selectedPOAId",
		["scan"],
	],
	scan: ["selectedPOAId", "poaFile"],
};

const persistedScanReducer = persistReducer(scanPersistConfig, scanReducer);

const rootReducer = combineReducers({
	account: accountReducer,
	scan: persistedScanReducer,
});

export default rootReducer;
