import axios from "axios";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import ResponsivePagination from "react-responsive-pagination";
import { reportData } from "../../data/reportData";
import DashboardLayout from "../../components/Dashboard/layout";
import TopLayout from "../../components/Dashboard/toplayout";
import ReportsTable from "../../components/Dashboard/reportsTable";
import "react-responsive-pagination/themes/classic.css";

const Reports = () => {
  const [tbData, setTbData] = useState(reportData.slice(0, 10));

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [uids, setUids] = useState([]);

  function handlePageChange(page) {
    setCurrentPage(page);
    setTbData(reportData.slice((page - 1) * 10, page * 10));
  }

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const allItemIds = reportData.map((item) => item.id);
      setSelectedItems(allItemIds);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };

  const hasRunOnce = useRef(false);
  const [analysisResult, setAnalysisResult] = useState(null);

  useEffect(() => {
    if (!hasRunOnce.current) {
      getAnalysis();
      hasRunOnce.current = true;
    }
  }, []);

  const getAnalysis = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASIC_API_URL}/analysis/`,
        {
          params: {
            page: 1,
            page_size: 300,
          },
        }
      );

      const uniqueDomains = new Set();
      const filteredResults = response.data.results
        .filter((item) => {
          const isUnique =
            !uniqueDomains.has(item.domain) &&
            item.status === "initial_complete";
          if (isUnique) {
            uniqueDomains.add(item.domain);
          }
          return isUnique;
        })
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      setAnalysisResult(filteredResults);
    } catch (error) {
      console.error("Error fetching analysis:", error);
    }
  };

  const handleDownloadCSV = async (itemId) => {
    try {
      const response = await axios.get(
        `https://api.spoofguard.io/analysis/${itemId}/variations/export/`,
        {
          responseType: "blob",
          headers: {
            Accept: "*/*",
          },
        }
      );

      const csvURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = csvURL;

      const disposition = response.headers["content-disposition"];
      const fileName = disposition
        ? disposition.split("filename=")[1].replace(/"/g, "")
        : `analysis-${moment().format("YYYY-MM-DD-hh-mm-SS")}.csv`;

      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const handleDownloadCSVSecond = async (itemId) => {
    try {
      const response = await axios.get(
        `https://api.spoofguard.io/analysis/${itemId}/export/`,
        {
          responseType: "blob",
          headers: {
            Accept: "*/*",
          },
        }
      );

      const csvURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = csvURL;

      const disposition = response.headers["content-disposition"];
      const fileName = disposition
        ? disposition.split("filename=")[1].replace(/"/g, "")
        : `analysis-${moment().format("YYYY-MM-DD-hh-mm-SS")}.csv`;

      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const total =
    analysisResult && analysisResult.length > 0
      ? parseInt(analysisResult.length / 10) + 1
      : 0;
  const totalCount =
    analysisResult && analysisResult.length > 0 ? analysisResult.length : 0;

  return (
    <DashboardLayout>
      <div className="p-4 md:p-8 max-w-[1600px] mx-auto" style={{margin: '0', width: '100%', maxWidth: '100%'}}>
        {/**
              <div className="hidden md:block">
          <TopLayout title="Reports" />
              </div>
               */}
              <div className="bg-white rounded-lg shadow" style={{boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)'}}>
                 <p className="pl-4 pt-3" style={{    margin: '0',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight: '1.334',
    letterSpacing: '0em'}}>Reports</p> 
          <ReportsTable
            analysisResult={analysisResult}
            currentPage={currentPage}
            selectedItems={selectedItems}
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
            handleCheckboxChange={handleCheckboxChange}
            handleExportCSV={handleDownloadCSV}
            handleDownloadCSVSecond={handleDownloadCSVSecond}
            setUids={setUids}
          />
          <div className="flex flex-col md:flex-row justify-between items-center gap-4 p-4 border-t">
            <p className="text-sm">
              {totalCount > 0 ? (
                <>
                  Showing {(currentPage - 1) * 10 + 1} to{" "}
                  {Math.min(currentPage * 10, totalCount)} of {totalCount} entries
                </>
              ) : (
                "No entries found"
              )}
            </p>
            <div className="w-full md:w-auto">
              <ResponsivePagination
                total={total}
                current={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Reports;
