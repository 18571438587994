import React, { useState } from "react";
import {
    TableCell,
    TableRow,
    IconButton,
    Switch,
    CircularProgress,
    Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateSingleMonitor } from "../../api";
import { enqueueSnackbar } from "notistack";
import { format, parseISO } from 'date-fns';


const MonitorRow = ({ monitor, takedown, onRowClick, onMonitorDelete }) => {
    const [monitorEnabled, setMonitorEnabled] = useState(monitor.enabled);
    const [monitorLoading, setMonitorLoading] = useState(false);


    const getTakedownStatusColor = (status) => {
        switch (status) {
            case 'Completed': return 'success.main';
            case 'In Progress': return 'info.main';
            case 'Draft': return 'warning.main';
            case 'Not Initiated': return 'text.secondary';
            default: return 'text.primary';
        }
    };

    const handleToggle = (e, id) => {
        e.stopPropagation();
        // Handle toggle logic here
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        onMonitorDelete(monitor)
    };

    return (

        <TableRow
            key={monitor.id}
            onClick={(e) => {
                onRowClick(monitor.uid)
            }}
            sx={{
                cursor: 'pointer',
                '&:hover': { backgroundColor: 'action.hover' }
            }}
        >
            <TableCell>{monitor.domain}</TableCell>
            <TableCell>{format(parseISO(monitor.created_at), 'dd MMM yyyy')}</TableCell>
            <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Switch
                        onClick={(e) => e.stopPropagation()} // Prevent the row's onClick from triggering
                        checked={monitorEnabled}
                        onChange={(e) => {
                            e.stopPropagation();
                            setMonitorLoading(true)
                            updateSingleMonitor(monitor.analysis_id, {
                                monitor_ids: [monitor.uid],
                                enabled: !monitorEnabled,
                            })
                                .then((response) => {
                                    setMonitorEnabled(response.data[0].enabled);
                                    setMonitorLoading(false)
                                    enqueueSnackbar("Monitor updated successfully", {
                                        variant: "success",
                                    });
                                })
                                .catch(() => {
                                    setMonitorLoading(false)
                                    enqueueSnackbar("Could not update monitor", {
                                        variant: "error",
                                    });
                                });
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                    {monitorLoading &&
                        <CircularProgress size={20} />
                    }
                </Box>

            </TableCell>
            <TableCell>{monitor.grouped_alerts?.total_count}</TableCell>
            <TableCell sx={{ color: getTakedownStatusColor(takedown?.status ?? "Not Initiated") }}>
                {takedown?.status ?? "Not Initiated"}
            </TableCell>
            <TableCell>
                <IconButton
                    onClick={(e) => handleDelete(e, monitor.id)}
                    size="small"
                >
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default MonitorRow;
