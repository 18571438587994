
import { useState, useRef, useEffect } from "react"
import {
    Button,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Switch,
    Tooltip,
} from "@mui/material"
import { getBranding, getLogos, uploadBranding } from "../../api"
import { enqueueSnackbar } from "notistack"
import { useDispatch } from "react-redux"
import { Info } from "@mui/icons-material"
import { updateAvailableLogos } from "../../slices/scan-slice"

const DetectSpoofingModal = ({ open, onClose, onSubmit, selectedDomains }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [logo, setLogo] = useState(null)
    const [selectedLogo, setSelectedLogo] = useState("")
    const [isCreatingKeywords, setIsCreatingKeywords] = useState(false)
    const [keywordList, setKeywordList] = useState(["", "", ""]);
    const [savedKeywordList, setSavedKeywordList] = useState(null)
    const fileInputRef = useRef(null)
    const [availableLogos, setAvailableLogos] = useState([]);
    const [availableBranding, setAvailableBranding] = useState([]);
    const [brandingName, setBrandingName] = useState("");
    const [selectedBrandingId, setSelectedBrandingId] = useState("");
    const [keywordMatchType, setKeywordMatchType] = useState("and")

    const dispatch = useDispatch();
    const isDetectSpoofingEnabled = !!selectedLogo && !!savedKeywordList

    const handleLogoUpload = (event) => {
        if (event.target.files && event.target.files[0]) {
            setLogo(event.target.files[0])
            setSelectedLogo("")
        }
    }

    const handleLogoSelect = (event) => {
        setSelectedLogo(event.target.value)
        setLogo(null)
    }

    const handleKeywordChange = (index, value) => {
        setKeywordList((prev) => {
            const updated = [...prev];
            updated[index] = value;
            return updated;
        });
    }

    const handleSaveKeywords = async () => {
        setSavedKeywordList(keywordList)
        setIsCreatingKeywords(false)

        if (!brandingName || keywordList.filter((k) => k).length === 0) {
            enqueueSnackbar("Please provide both name and keywords", {
                variant: "error",
            });
            return;
        }

        try {
            const data = {
                name: brandingName,
                keywords: keywordList.filter((k) => k),
            };

            await uploadBranding(data);

            // Refresh branding list
            const response = await getBranding();
            setAvailableBranding(response.data);

            setSelectedBrandingId(response.data.filter(x => x.name === brandingName)[0].id)
            // Reset form
            setBrandingName("");
            setIsCreatingKeywords(false);

            enqueueSnackbar("Branding keywords saved successfully", {
                variant: "success",
            });
        } catch (error) {
            enqueueSnackbar("Error saving branding", { variant: "error" });
        }
    }

    const handleCancelKeywords = () => {
        setIsCreatingKeywords(false)
        setKeywordList({ name: "", keyword1: "", keyword2: "", keyword3: "" })
    }

    const handleDetectSpoofing = () => {
        console.log("Detecting spoofing...")
        setIsModalOpen(false)

        //Send modal selection to the parent component where the request is handled
        const data = {
            logoId: selectedLogo,
            brandId: savedKeywordList,
            spoofingOp: keywordMatchType
        }

        onSubmit(data)
        onClose()
    }


    useEffect(() => {
        const fetchLogos = async () => {
            try {
                const response = await getLogos();
                setAvailableLogos(response.data);
                dispatch(updateAvailableLogos(response.data));
            } catch (error) {
                enqueueSnackbar("Unable to fetch logos", { variant: "error" });
            }
        };
        fetchLogos();
    }, [dispatch]);

    useEffect(() => {
        const fetchBranding = async () => {
            try {
                const response = await getBranding();
                setAvailableBranding(response.data);
            } catch (error) {
                enqueueSnackbar("Unable to fetch branding lists", {
                    variant: "error",
                });
            }
        };
        fetchBranding();
    }, []);

    return (
        <Dialog open={open} onClose={() => setIsModalOpen(false)} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography mb={2} variant="h5" component="div">
                    Detect Spoofing
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Upload your company logo or enter brand-related keywords found on your website—or both—for better accuracy.
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <FormControl sx={{ marginBottom: 3 }}>
                        <Typography mb={1}>Select Logo</Typography>

                        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                            <Select
                                fullWidth
                                value={selectedLogo}
                                onChange={handleLogoSelect}>
                                {availableLogos?.map((logo) => (
                                    <MenuItem key={logo.id} value={logo.id}>
                                        {logo.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Typography>or</Typography>
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleLogoUpload}
                                style={{ display: "none" }}
                                accept="image/*"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => fileInputRef.current.click()}
                            >
                                Upload
                            </Button>
                        </Box>
                    </FormControl>

                    <Typography>Branding Keywords</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Tooltip title={"Select AND (default) to require all keywords or OR to match any keyword on the website."}>
                            <Info sx={{ color: 'grey', fontSize: 20, marginRight: 1 }} />
                        </Tooltip>
                        <span className="text-sm font-medium">AND</span>
                        <Switch
                            checked={keywordMatchType === "or"}
                            onChange={(e) => setKeywordMatchType(e.target.checked ? "or" : "and")}
                            color="default"
                            sx={{
                                "& .MuiSwitch-thumb": {
                                    color: "grey",
                                },
                                "& .MuiSwitch-track": {
                                    backgroundColor: "lightgray",
                                    opacity: 1,
                                },
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                    color: "grey", 
                                },
                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                    backgroundColor: "lightgray",
                                    opacity: 1,
                                },
                            }}
                        />
                        <span className="text-sm font-medium">OR</span>
                    </Box>

                    <FormControl>

                        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                            <Select
                                fullWidth
                                value={savedKeywordList}
                                onChange={(event) => {
                                    setSavedKeywordList(event.target.value)
                                    setIsCreatingKeywords(false)
                                }}
                            >
                                {availableBranding?.map((brand) => (
                                    <MenuItem key={brand.id} value={brand.id}>
                                        {brand.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Typography>or</Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setIsCreatingKeywords(true)}
                            >
                                Create
                            </Button>
                        </Box>
                    </FormControl>

                </Box>

                {isCreatingKeywords && (
                    <Box mt={3} sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                            fullWidth
                            label="Branding List Name"
                            value={brandingName}
                            onChange={(e) => setBrandingName(e.target.value)}
                        />

                        <TextField
                            fullWidth
                            label="Keyword 1"
                            value={keywordList[0]}
                            onChange={(e) => handleKeywordChange(0, e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Keyword 2"
                            value={keywordList[1]}
                            onChange={(e) => handleKeywordChange(1, e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Keyword 3"
                            value={keywordList[2]}
                            onChange={(e) => handleKeywordChange(2, e.target.value)}
                            margin="normal"
                        />
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "16px" }}>

                                <Button variant="outlined" onClick={handleCancelKeywords}>
                                    Cancel
                                </Button>

                                <Button variant="contained" color="primary" onClick={handleSaveKeywords}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Box>
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleDetectSpoofing}
                    disabled={!isDetectSpoofingEnabled}
                    color="primary"
                    variant="contained"
                >
                    Detect Spoofing
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DetectSpoofingModal





// import React, { useState } from 'react';
// import {
//     Modal,
//     Box,
//     Typography,
//     Button,
//     TextField,
//     Tooltip,
// } from '@mui/material';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import InfoIcon from '@mui/icons-material/Info';
// import { saveFileToLocalStorage } from '../../utiles/general';
// import { useDispatch } from 'react-redux';
// import { updateBrandLogo } from '../../slices/scan-slice';

// export default function DetectSpoofingModal({ open, onClose, onSubmit, selectedDomains }) {
//     const dispatch = useDispatch();


//     const [brandInfo, setBrandInfo] = useState({
//         brandName: '',
//         logo: null
//     });

//     const handleBrandNameChange = (event) => {
//         setBrandInfo(prev => ({
//             ...prev,
//             brandName: event.target.value
//         }));
//     };

//     const handleLogoUpload = (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             setBrandInfo(prev => ({
//                 ...prev,
//                 logo: file
//             }));
//         }
//     };

//     const handleDetectSpoofing = async () => {
//         console.log(1)

//         if (brandInfo.logo) {
//             try {
//                 const fileKey = await saveFileToLocalStorage(brandInfo.logo);
//                 dispatch(updateBrandLogo(fileKey));
//             } catch (error) {
//                 console.error("Error uploading file:", error);
//             }

//             //dispatch(addBrand(brandInfo.brandName))
//         }

//         onSubmit()
//         onClose()
//     };

//     return (
//         <Modal
//             open={open}
//             onClose={onClose}
//             aria-labelledby="detect-spoofing-modal-title"
//         >
//             <Box sx={{
//                 position: 'absolute',
//                 top: '50%',
//                 left: '50%',
//                 transform: 'translate(-50%, -50%)',
//                 width: 600,
//                 bgcolor: 'background.paper',
//                 borderRadius: 1,
//                 boxShadow: 24,
//                 p: 4,
//             }}>
//                 <Typography
//                     id="detect-spoofing-modal-title"
//                     variant="h5"
//                     component="h2"
//                     sx={{
//                         mb: 3,
//                         fontWeight: 'normal'
//                     }}
//                 >
//                     Detect Spoofing
//                 </Typography>

//                 <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                     <Typography
//                         variant="h6"
//                         sx={{
//                             fontWeight: 'normal',
//                             mr: 1
//                         }}
//                     >
//                         Add Branding or Logo
//                     </Typography>
//                     <Tooltip title="Upload your brand name and logo to detect spoofing attempts">
//                         <InfoIcon fontSize="small" sx={{ color: '#1976d2' }} />
//                     </Tooltip>
//                 </Box>

//                 <TextField
//                     fullWidth
//                     label="Brand Name"
//                     variant="outlined"
//                     value={brandInfo.brandName}
//                     onChange={handleBrandNameChange}
//                     sx={{
//                         mb: 2,
//                         '& .MuiOutlinedInput-root': {
//                             '& fieldset': {
//                                 borderColor: '#1976d2',
//                             },
//                             '&:hover fieldset': {
//                                 borderColor: '#1976d2',
//                             },
//                             '&.Mui-focused fieldset': {
//                                 borderColor: '#1976d2',
//                             },
//                         },
//                         '& .MuiInputLabel-root': {
//                             color: '#1976d2',
//                             fontSize: '0.875rem',
//                         },
//                         '& .MuiOutlinedInput-input': {
//                             fontSize: '0.875rem',
//                         },
//                     }}
//                 />

//                 <Button
//                     component="label"
//                     variant="outlined"
//                     startIcon={<CloudUploadIcon />}
//                     sx={{
//                         mb: 4,  // Changed from mb: 3
//                         color: '#1976d2',
//                         borderColor: '#1976d2',
//                         '&:hover': {
//                             borderColor: '#1565c0',
//                             bgcolor: 'rgba(25, 118, 210, 0.04)',
//                         },
//                         fontSize: '0.875rem',
//                         py: 0.75,
//                     }}
//                 >
//                     UPLOAD LOGO
//                     <input
//                         type="file"
//                         hidden
//                         accept="image/*"
//                         onChange={handleLogoUpload}
//                     />
//                 </Button>

//                 {brandInfo.logo && (
//                     <Typography variant="body2" sx={{ mb: 3, color: 'text.secondary' }}>
//                         Selected file: {brandInfo.logo.name}
//                     </Typography>
//                 )}

//                 <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
//                     <Button
//                         onClick={onClose}
//                         sx={{
//                             color: '#1976d2',
//                             fontSize: '0.875rem'
//                         }}
//                     >
//                         CANCEL
//                     </Button>
//                     <Button
//                         variant="contained"
//                         onClick={() => handleDetectSpoofing()}
//                         sx={{
//                             bgcolor: '#FFC985',
//                             color: 'black',
//                             '&:hover': { bgcolor: '#E6B578' },
//                             fontSize: '0.875rem',
//                             px: 2,
//                             py: 0.75,
//                         }}
//                     >
//                         DETECT SPOOFING
//                     </Button>
//                 </Box>
//             </Box>
//         </Modal>
//     );
// }

